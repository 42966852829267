import { useTranslate } from "@tolgee/react";
import React, { useEffect, useState } from "react";
import {Col, Container, ListGroup, Row, Tab} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { IForm, IFormBasicData } from "../../api/types/Form";
import { IFormElement } from "../../api/types/FormElement";
import { OutSideSubmit } from "../../comp/form/Forms";
import {
  MultiSelectFieldProps, MultiSelectOptions, StatePre,
  BasicInputFieldProps,
} from "../../comp/form/FormTypes";
import { ModalForm } from "../../comp/form/ModalForm";
import {
  DraggableFormProvider as DragDropProvider,
  DraggableFormProvider,
} from "../../contextProvider/DragDropProvider";
import { useDataContext } from "../../contextProvider/ModuleProvider";
import { outOfRangeValidator } from "../../validationHandler/OutOfRangeValidator";

import { fieldNotSameNameHandler as fieldNotSameValueHandler } from "../../validationHandler/NotSameNameHandler";
import { useApiRequest } from "../../hooks/useApiRequest";
import { v4 as uuid } from "uuid";
import {SelectItem} from "primereact/selectitem";
import {MultiSelect} from "primereact/multiselect";
import {FormViewer, SelectOptions} from "./FormViewer";
import {
  allowRoleFieldProps,
  anonymousFieldProps,
  inboxFieldProps,
  linkChannelIdFieldProps,
  linkMessageChannelIdFieldProps
} from "./FormBuilderProps";
import {fieldNotEmptyValidator} from "../../validationHandler/NotEmptyValidator";

export const FormBuilder = () => {
  //console.log("Formbuilder rendered");
  const t = useTranslate();
  const { data: formsData, refresh: refreshFormData } = useDataContext().forms;
  const { data: discordData } = useDataContext().discordData;
  const { id } = useParams();


  const [createNewFormValid, setCreteNewFormValid] = useState(false);

  //console.log("discorddata: ", discordData);

  const [newBasicFormData, setNewBasicFormData] = useState<StatePre<IFormBasicData>>(
    {} as IFormBasicData
  );

  const { executeRequest, data, error } = useApiRequest<IFormBasicData>(
    "data",
    "form/:guildId/create",
    "POST",
    false
  );
  //select data
  const nav = useNavigate();

  const [discordFormEntreis, setDiscordFormEntreis] =
    useState<IFormElement[]>();
  const [activeForm, setActiveForm] = useState<IForm>();
  const [createModalVisible, setCreateModalVisible] = useState(false);
  let discordChannels: SelectOptions[] = [];
  if (discordData) {
    discordChannels = discordData?.discordChannels
      .filter((e) => e.channelType === 0 || e.channelType === 4)
      .map((value, index) => {
        const prefix = value.channelType === 0 ? "- " : "# ";
        return { name: prefix + value.channelName, value: value.channelId };
      });
  }

  const guildIdFieldProps: BasicInputFieldProps<IFormBasicData> = {
    inputFieldType: "number",
    name: "guildId",
    label: "guildId",
    readOnly: true,
    inline: false,
  };

  useEffect(() => {
    if (activeForm) {
      setDiscordFormEntreis(activeForm?.entries);
    }
  }, [activeForm]);
  useEffect(() => {
    //console.log("forms updated");
  }, [formsData]);
  useEffect(() => {
    //console.log(formsData?.length);
  }, [formsData]);
  if (!formsData) {
    return <>error</>;
  }

  const createNewFormApiRequest = async () => {
    if (id) {
      await executeRequest(newBasicFormData, { guildId: id });
    }
  };

  let formFieldNames: string[] = formsData.map(
    (value) => value.basicFormData.customId
  );

  const customIdFieldProps: BasicInputFieldProps<IFormBasicData> = {
    inputFieldType: "string",
    name: "customId",
    label: "Form name",
    readOnly: false,
    inline: false,
    validationOptions: {
      minMaxValidation: { max: 40, min: 0 },
      notSameStringValidation: {
        stringValues: formFieldNames,
        maxRepeatAmount: 0,
      },
    },
    validationOnSubmit: {
      minMaxValidation: outOfRangeValidator,
      notSameStringValidation: fieldNotSameValueHandler,
      notEmptyValidation: fieldNotEmptyValidator,
    },

    validationOnBlur: {
      minMaxValidation: outOfRangeValidator,
      notSameStringValidation: fieldNotSameValueHandler,
      notEmptyValidation: fieldNotEmptyValidator,
    },
  };

  let allowedRoles:MultiSelectOptions[] = [];

  if(discordData) {
    allowedRoles = discordData?.discordRoles.map((e) => {return {name: e.name, code: e.roleId}});
  }

  const resetNewFormCreat = () => {
    if (id) {
      setNewBasicFormData({
        customId: "",
        guildId: id,
        anonymous: true,
        uuid: uuid(),
        channelId: "0",
        linkChannelId: "0",
        messageInteractionId: "0",
      });
    }
  };

  const onSubmitValid = async () => {
    await createNewFormApiRequest();
    setCreateModalVisible(false);
    refreshFormData();
    resetNewFormCreat();
  };

  return (
    <Container>
      <h1>{t("TITTLE_FORM_BUILDER")}</h1>
      <Tab.Container id="list-group-tabs-example" defaultActiveKey="#link1">
        <Row>
          <Col sm={4}>
            <ListGroup>
              {formsData &&
                formsData.map((form, i) => {
                  return (
                    <ListGroup.Item
                      key={i}
                      action
                      href={"#" + form.basicFormData.customId}
                      onClick={async (ea) => {
                        setActiveForm(form);
                      }}
                    >
                      {form.basicFormData.customId}
                    </ListGroup.Item>
                  );
                })}
              <ListGroup.Item
                action
                onClick={(e) => {
                  e.preventDefault();
                  resetNewFormCreat();
                  setCreateModalVisible(true);
                }}
                className="text-center"
              >
                {t("FORM_BUILDER_NEW_FORM")}
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col sm={8}>
            {" "}
            <Tab.Content>
              {formsData &&
                formsData?.map((form, i) => {
                  return (
                    <Tab.Pane
                      key={i}
                      eventKey={"#" + form.basicFormData.customId}
                    >
                      <FormViewer
                        form={form}
                        discordChannels={discordChannels}
                        allowRoles={allowedRoles}
                      />
                    </Tab.Pane>
                  );
                })}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
      <ModalForm<IFormBasicData>
        title="Create new Form"
        handleClose={() => {
          resetNewFormCreat();
          setCreateModalVisible(false);
        }}

        showModal={createModalVisible}
        formProps={{
          isModal: true,
          fieldMetaData: {
            guildId: guildIdFieldProps,
            customId: customIdFieldProps,
            channelId: {
              ...inboxFieldProps,
              selectOptions: discordChannels,
            },
            allowedRoles: {...allowRoleFieldProps, multiSelectOptions: allowedRoles},
            anonymous: anonymousFieldProps,
            linkChannelId: linkChannelIdFieldProps,
            messageInteractionId: linkMessageChannelIdFieldProps,
          },
          state: newBasicFormData,
          onStateChange: setNewBasicFormData,
          onSubmitValid: onSubmitValid,
        }}
      ></ModalForm>
    </Container>
  );
};
