import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  DefaultInputFieldProps,
  FieldPropsMapper,
  FieldPropTypes,
  SelectInputFieldProps,
  BasicInputFieldProps,
} from "./FormTypes";
import { SelectInputField } from "./inputs/SelectInputField";
import { MultiSelectDropDown } from "./inputs/MultiSelectDropDown";
import TextInputField from "./inputs/TextInputField";
import {ApiSelectField} from "./inputs/ApiSelectField";
import {ColorPickerInputField} from "./inputs/ColorPickerInputField";

export interface FormRenderWopper<T> {
  fieldsMetaData: FieldPropsMapper<T>;
}

export const RenderInput = <T,>(fieldProps: FieldPropTypes<T>) => {
  if (
    fieldProps.inputFieldType === "string" ||
    fieldProps.inputFieldType === "number"
  ) {
    return <TextInputField {...fieldProps} />;
  } else if (
    fieldProps.inputFieldType === "select" ||
    fieldProps.inputFieldType === "switch"
  ) {
    return <SelectInputField {...fieldProps} />;
  } else if (fieldProps.inputFieldType === "multiselect") {
    return  <MultiSelectDropDown {...fieldProps} />
  } else if (fieldProps.inputFieldType === "apiSelect") {
    return  <ApiSelectField {...fieldProps} />
  } else if (fieldProps.inputFieldType === "color") {
    return  <ColorPickerInputField {...fieldProps} />
  } else {
    return <p>unexpected error legit you broke the type</p>;
  }
};

export const FormRender = <T,>({ fieldsMetaData }: FormRenderWopper<T>) => {
  const keys = Object.keys(fieldsMetaData) as Array<keyof T>;
  const rows: FieldPropTypes<T>[][] = [];
  let tempArr: FieldPropTypes<T>[] = [];
  keys.forEach((e) => {
    const fieldMetaData = fieldsMetaData[e];
    if (fieldMetaData) {
      if (fieldMetaData?.inline) {
        tempArr.push(fieldMetaData);
      } else {
        rows.push(tempArr);
        tempArr = [fieldMetaData];
      }
    }
  });
  rows.push(tempArr);

  return (
    <div className="mt-3 flex flex-col">
      {rows.map((inlinediv, index) => {
        return (
          <Row key={index} className="mb-0">
            {inlinediv.map((fieldMetaData, innerIndex) => {
              return (
                <RenderInput key={innerIndex} {...fieldMetaData}></RenderInput>
              );
            })}
          </Row>
        );
      })}
    </div>
  );
};
