import React, {useContext, useEffect, useState} from 'react'
import {StatePre} from "../comp/form/FormTypes";
import {DropResult} from "react-beautiful-dnd";
import {isEqual, once} from "lodash";
import {
    IEmbed,
    IEmbedAuthor,
    IEmbedBasicData,
    IEmbedField,
    IEmbedFooter,
    IEmbedName,
    IEmbedTitle
} from "../api/types/IEmbed";
import {T} from "@tolgee/react";

export interface EmbedAction {
    isCreator: boolean,
    uuid: string,
    name: StatePre<IEmbedName>,
  author: StatePre<IEmbedAuthor>,
  title: StatePre<IEmbedTitle>,
  basicData: StatePre<IEmbedBasicData>,
  fields: StatePre<IEmbedField>[],
  footer: StatePre<IEmbedFooter>,
  resetChanges: () => void;
  hasChanges: () => boolean,
  refresh: () => void,
  onNameChange: (value: StatePre<IEmbedName>) => void;
  onAuthorChange: (value: StatePre<IEmbedAuthor>) => void;
  onTitleChange: (value: StatePre<IEmbedTitle>) => void;
  onBasicDataChange: (value: StatePre<IEmbedBasicData>) => void;
  onFieldsChange: (fields: StatePre<IEmbedField>[]) => void;
  onFooterChange: (value: StatePre<IEmbedFooter>) => void;
}

export interface EmbedActionProps {
  embed: IEmbed;
  refresh: () => void;
  children: React.ReactNode;
}

const createEmbedContext = once(() =>
    React.createContext({} as EmbedAction)
);
export const useEmbedContext = () =>
    useContext(createEmbedContext());
export const EmbedProvider = ({embed, children, refresh}: EmbedActionProps) => {

  const EmbedContext = createEmbedContext();
  const [name, setName] = useState<StatePre<IEmbedName>>(embed.name);
  const [author, setAuthor] = useState<StatePre<IEmbedAuthor>>(embed.author);
  const [title, setTitle] = useState<StatePre<IEmbedTitle>>(embed.title);
  const [basicData, setBasicData] = useState<StatePre<IEmbedBasicData>>(embed.basicData);
  const [fields, setFields] = useState<StatePre<IEmbedField>[]>(embed.fields);
  const [footer, setFooter] = useState<StatePre<IEmbedFooter>>(embed.footer);

    useEffect(() => {
        setAuthor(embed.author);
        setTitle(embed.title);
        setBasicData(embed.basicData);
        setFields(embed.fields);
        setFooter(embed.footer);
    }, [embed]);
  const onNameChange = (value: StatePre<IEmbedName>) => {
      setName(value);
  }
  const onAuthorChange = (value: StatePre<IEmbedAuthor>) => {
    setAuthor(value);
  }
  const onTitleChange = (value: StatePre<IEmbedTitle>) => {
    setTitle(value);
  }

  const onBasicDataChange = (value: StatePre<IEmbedBasicData>) => {
    setBasicData(value);
  }

  const onFieldsChange = (fields: StatePre<IEmbedField>[]) => {
    setFields([...fields]);
  }

  const onFooterChange = (value: StatePre<IEmbedFooter>) => {
    setFooter(value);
  }

  const resetChanges = () => {
      setName({...embed.name});
      setAuthor(embed.author);
      setTitle(embed.title);
      setBasicData(embed.basicData);
      setFields(embed.fields);
      setFooter(embed.footer);
  }

  const hasChanges = () => {
    return isEqual(embed.name, name)
        && isEqual(embed.author, author)
        && isEqual(embed.title, title)
        && isEqual(embed.basicData, basicData)
        && isEqual(embed.fields, fields)
        && isEqual(embed.footer, footer);
  };


  return (
      <EmbedContext.Provider
        value={{
            isCreator: false,
            uuid: embed.uuid,
          name: name,
          author: author,
          title: title,
          basicData: basicData,
          fields: fields,
          footer: footer,
          refresh: refresh,
          resetChanges: resetChanges,
          hasChanges: hasChanges,
          onNameChange: onNameChange,
          onAuthorChange: onAuthorChange,
          onBasicDataChange: onBasicDataChange,
          onTitleChange: onTitleChange,
          onFieldsChange: onFieldsChange,
          onFooterChange: onFooterChange
                }}>
        {children}
      </EmbedContext.Provider>
  );
}

