import React, {useEffect, useState} from 'react'
import {FieldPropsMapper, StatePre} from "./FormTypes";
import {DragDropContext, Draggable, Droppable, DropResult} from "react-beautiful-dnd";
import {Button, ListGroup, OverlayTrigger, Tooltip} from "react-bootstrap";
import {UUIDInterface} from "../../api/types/FormElement";
import {Forms, OutSideSubmit} from "./Forms";
import {T} from "@tolgee/react";


export type FormListProps<T, K={}> = {
    partName: keyof K,
  draggable: boolean,
  defaultElement: StatePre<T>,
  elementLimit?: number,
  states: StatePre<T>[],
  fieldMetaData: FieldPropsMapper<T>;
  onFormChange: (elements: StatePre<T>[]) => void,
  submit: number,
  onValidChange: (valid: boolean, name: keyof K) => void,
};
export const FormList =<T extends UUIDInterface,K={}> ({partName, defaultElement, states, fieldMetaData, elementLimit, onFormChange, onValidChange, submit}:FormListProps<T, K>) => {
    //console.log("fromlist rerender: ", states);
    const [formListState, setFormListState ] = useState<StatePre<T>[]>(states);
    const [syncFormListState, setSyncFormListState ] = useState<StatePre<T>[]>(states);

    //validation rework
    let initValidState:Record<string, boolean> = {};
    states.forEach((e) => {initValidState[e.uuid.toString()] = false});

    const [validList, setValidList] = useState<Record<string, boolean>>(initValidState);

    //update outside FormListValidState
    useEffect(() => {
        let valid = true;
        //problem list must nicht aktuell sein beim ersten key
        Object.keys(validList).forEach((e) => {if (!validList[e]) {valid = false}});
        onValidChange(valid, partName);
        //console.log(valid);
    }, [validList]);

    useEffect(() => {
        if(formListState.length <= 0) {
            onValidChange(true, partName);
        }
    }, [formListState]);


    const updateStateUpwards = (elements:StatePre<T>[]) => {
        setSyncFormListState([...elements]);
        onFormChange(elements);
    };

    const updateAllStates = (elements:StatePre<T>[]) => {
        updateStateUpwards(elements);
        setFormListState([...elements]);
    };

    const AddButton = () => {
        return <Button onClick={(e) => {
                onNewElement({...defaultElement});
            }}
        >
            +
        </Button>;
    }

    const onValidSubmit = () => {
        //console.log("nice");
    };

    const reorder = (list: StatePre<T>[], startIndex: number, endIndex: number) => {
        let result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result = Array.from(result);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = ({ destination, source }: DropResult) => {
        if (!destination) return;

        let newItems: StatePre<T>[] = [];

        newItems = reorder(syncFormListState, source.index, destination.index);
        updateAllStates(newItems);
    }

    const onNewElement = (element: StatePre<T>) => {
        validList[element.uuid.toString()] = false;
        setValidList({...validList});
        updateAllStates([...syncFormListState, element]);
    };

    const onDeleteElement = (element: StatePre<T>) => {
        let newItems: StatePre<T>[] = [];
        newItems = Array.from(syncFormListState);
        const index = newItems.indexOf(element);
        newItems.splice(index, 1);
        updateAllStates(newItems);
        newItems.forEach((e) => {initValidState[e.uuid.toString()] = false});
        setValidList({...initValidState});
    };

    const setLocalState = (element: StatePre<T>) => {
        const elementUnderEdit = syncFormListState.find((e) => e.uuid === element.uuid);
        if (elementUnderEdit) {
            const index = syncFormListState.indexOf(elementUnderEdit);
            let tempArr = Array.from(syncFormListState);
            tempArr.splice(index, 1);
            tempArr.splice(index, 0, element);
            updateStateUpwards(tempArr);
        }
    };

  return (
      <div>
          <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable-list">
                  {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                  {formListState &&
                      formListState.map((element, i) => {
                      const updateValidList = (value: boolean) => {
                          validList[element.uuid.toString()] = value;
                          setValidList({...validList});
                      }
                      const outSideSubmit: OutSideSubmit = {
                          setValid: updateValidList,
                          triggerSubmit: submit,
                      };
                      return (<Draggable key={element.uuid.toString()} draggableId={element.uuid.toString()} index={i}>
                        {(provided, snapshot) => (
                            <ListGroup.Item
                                {...provided.draggableProps}
                                ref={provided.innerRef}
                                className="pl-0"
                            >
                                <div className="flex flex-row w-full">
                                    <span {...provided.dragHandleProps} className="material-icons grab self-center">
                                      drag_indicator
                                    </span>
                                    <div className="w-full flex flex-col justify-between">
                                        <div className="w-full">
                                            <span
                                                className="material-icons float-right"
                                                onClick={(e) => {
                                                    onDeleteElement(element);
                                                }}
                                            >
                                              close
                                            </span>

                                            <Forms<T>
                                                isModal={false}
                                                state={element}
                                                fieldMetaData={fieldMetaData}
                                                onStateChange={setLocalState}
                                                outSideSubmit={outSideSubmit}
                                                onSubmitValid={onValidSubmit}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </ListGroup.Item>
                        )}
                        </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
                )}
              </Droppable>

          </DragDropContext>
          <ListGroup.Item>
              <div className="flex justify-around">
                  {syncFormListState && elementLimit ? (<>{syncFormListState.length < elementLimit ? (<AddButton />):(
                      <OverlayTrigger key={"right"} placement={"right"} overlay={
                          <Tooltip id={`tooltip-${"right"}`}>
                                The maximum amount of <strong>{elementLimit} Elements</strong> have been
                                reach.
                          </Tooltip>
                        }
                      >
                        <Button>+</Button>
                      </OverlayTrigger>)}</>) : (<AddButton />)}
              </div>
          </ListGroup.Item>
      </div>
  )
}

