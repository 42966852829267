import {FormStateOnValueChangeTypeof, HandValidationResponse, ValidationOptions} from "../comp/form/FormTypes";

export const fieldNotEmptyValidator = (
    value: FormStateOnValueChangeTypeof,
    validationOptions?: ValidationOptions
) => {
    let handlerResponse: HandValidationResponse = {
        status: "success",
        message: "EVERYTHING_IS_FINE",
    };

    if (value.toString().length < 1) {
        handlerResponse = {
            status: "error",
            message: "FIELD_NOT_EMPTY",
        };
    }

    return handlerResponse;
};