import React, { Children, useEffect } from "react";
import logo from "./logo.svg";
import history from "./utils/history";
import "bootstrap/dist/css/bootstrap.min.css";
import MyNavbar from "./comp/navbar/MyNavbar";

import Home from "./sites/home/Home";
import EmbedBuilder from "./sites/embedBuilder/EmbedBuilder";
import Help from "./sites/help/Help";
import Loading from "./sites/loading/Loading";
import { useAuth0 } from "@auth0/auth0-react";
import { Route, Router, Routes, useParams } from "react-router-dom";
import { Container, ThemeProvider } from "react-bootstrap";

import { AppLocationWrapperProps, BaseApp } from "./BaseApp";
import AuthLayer from "./AuthLayer";
import { Guilds } from "./sites/dashboard/Guilds";
import { NotFound } from "./sites/notfound/NotFound";
import { FormBuilder } from "./sites/formbuilder/FormBuilder";
import {FeatureOverview} from "./sites/guildOverview/FeatureOverview";
import {SocialNotification} from "./sites/socialnotification/SocialNotification";

export const InnerRouter = () => {
  const { feature } = useParams();

  if (feature) {
    if (feature === "forms") {
      return <FormBuilder />;
    } else if (feature === "social") {
        return <SocialNotification />;
    } else if (feature === "embeds") {
      return <EmbedBuilder />;
    } else {
      return <NotFound />;
    }
  } else {
    return <NotFound />;
  }
};

const AppRouter = () => {
  const { user, isAuthenticated, loginWithRedirect, logout, isLoading, error } =
    useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  return (
    <div className="App">
      <ThemeProvider
        breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
        minBreakpoint="xxs"
      >
        <div className="default-spacing">
          <Routes>
            <Route
              path="/"
              element={
                <BaseApp>
                  <Home />
                </BaseApp>
              }
            />

            <Route
              path="/embed-builder"
              element={
                      <BaseApp>
                          <EmbedBuilder />
                      </BaseApp>

              }
            />
            <Route
              path="/help"
              element={

                      <BaseApp>
                          <Help />
                      </BaseApp>

              }
            />
            <Route path="/guilds" element={
                <BaseApp>
                  <AuthLayer>
                    <Guilds />
                  </AuthLayer>
                </BaseApp>
              }
            />
            <Route path="/guilds/:id"
              element={
                <BaseApp>
                  <AuthLayer>
                    <FeatureOverview />
                  </AuthLayer>
                </BaseApp>
              }
            />
            <Route
              path="/guilds/:id/:feature"
              element={
                <BaseApp>
                  <AuthLayer>
                    <InnerRouter />
                  </AuthLayer>
                </BaseApp>
              }
            />
          </Routes>
        </div>
      </ThemeProvider>
    </div>
  );
};
export default AppRouter;
