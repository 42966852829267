import React, {useEffect, useState} from 'react'
import {
    IEmbed,
    IEmbedAuthor,
    IEmbedBasicData,
    IEmbedField,
    IEmbedFooter,
    IEmbedName,
    IEmbedTitle
} from "../../api/types/IEmbed";
import {Button, ListGroup, ListGroupItem} from "react-bootstrap";
import {Forms, OutSideSubmit} from "../../comp/form/Forms";
import {FormStateOnValueChangeTypeof, StatePre} from "../../comp/form/FormTypes";
import {
    authorIconURLFieldProps,
    authorURLFieldProps,
    authorValueFieldProps, basicDataColorFieldProps,
    basicDataContentFieldProps,
    basicDataDescriptionFieldProps,
    basicDataImageURLFieldProps,
    basicDataThumbnailURLFieldProps,
    fieldInlineFieldProps,
    fieldNameFieldProps,
    fieldValueFieldProps,
    footerIconURLFieldProps,
    footerTimeStampProps,
    footerValueFieldProps, nameValueFieldProps,
    titleURLFieldProps,
    titleValueFieldProps
} from "./EmbedProperties";
import {FormList} from "../../comp/form/FormList";
import {fieldnameFieldPropsTemplate} from "../formbuilder/FormBuilderProps";
import {v4 as uuid} from "uuid";
import {useEmbedContext} from "../../contextProvider/EmbedProvider";
import {ColorPickerInputField} from "../../comp/form/inputs/ColorPickerInputField";
import {useApiRequest} from "../../hooks/useApiRequest";
import {isEqual} from "lodash";
import {useParams} from "react-router-dom";

export type BooleanMapper<T> = {
    [key in keyof T]?: (value: boolean) => void;
}
export const EmbedEditor = () => {
    const {uuid: activeUUID, name, author, title, basicData, fields, footer, onNameChange, onAuthorChange, onTitleChange, onBasicDataChange, onFieldsChange, onFooterChange, hasChanges, resetChanges, refresh } = useEmbedContext();
    const [submit, setSubmit] = useState(0);
    const {executeRequest, loading, data} = useApiRequest<IEmbed>("data", "embed/:guildId/insert", "POST", false);
    const {id} = useParams();
    const initValidateState = {uuid: true, name: false, author: false, title: false, basicData: false, fields: false, footer: false};
    const [nameValidate, setNameValidate] = useState(false);
    const [authorValidate, setAuthorValidate] = useState(false);
    const [titleValidate, setTitleValidate] = useState(false);
    const [basicDataValidate, setBasicDataValidate] = useState(false);
    const [fieldsValidate, setFieldsValidate] = useState(false);
    const [footerValidate, setFooterValidate] = useState(false);

    if(!id) {
        return <></>
    }


    const validationSetters:BooleanMapper<IEmbed> = {
        name: setNameValidate,
        author: setAuthorValidate,
        title: setTitleValidate,
        basicData: setBasicDataValidate,
        footer: setFooterValidate,
    }

    const updateValidate = (value: boolean, key: keyof IEmbed|undefined) => {
        if(key) {
            const setValid = validationSetters[key];
            if(setValid) {
                setValid(value);
            }
        }
    };

    const outSideSubmit:OutSideSubmit<IEmbed> = {
        triggerSubmit: submit,
        setValid: updateValidate
    }

    const handleSubmitPress = async () => {
        if(activeUUID === "CREATE") {
            resetChanges();
        }
        await onSubmitValidationSuccess();
        setSubmit(0);
    };

    const defaultFieldValueElement: StatePre<IEmbedField> = {
        value: "",
        name: "",
        inline: false,
        uuid: uuid()
    }

    const onSubmitValidationSuccess = async () => {
        let tempUUID = activeUUID;
        if(activeUUID === "CREATE") {
            tempUUID = uuid();
        };
        await executeRequest({uuid: tempUUID, name: name, author: author, title: title, basicData: basicData, fields: fields, footer: footer}, { guildId: id });
        refresh();
    };

    const handleCheckPress = () => {
        console.log("allvalid: ", allFieldsValid());
        setSubmit(submit + 1);
    };


    const allFieldsValid = () => {
        return (nameValidate && authorValidate && titleValidate && basicDataValidate && footerValidate);
    }

    return (
      <>
          <ListGroup>
              <ListGroupItem>
                  <h1 className="text-2xl">Embed name</h1>
                  <Forms<IEmbedName, IEmbed> state={name} partName={"name"} onStateChange={onNameChange} fieldMetaData={{value: nameValueFieldProps}} outSideSubmit={outSideSubmit} />
              </ListGroupItem>
              <ListGroupItem>
                  <h1 className="text-2xl">Author</h1>
                  <Forms<IEmbedAuthor, IEmbed> state={author} partName={"author"}
                                       onStateChange={onAuthorChange}
                                       fieldMetaData={{value: authorValueFieldProps, valueURL: authorURLFieldProps, iconURL: authorIconURLFieldProps}}
                                        outSideSubmit={outSideSubmit}/>

              </ListGroupItem>
              <ListGroupItem>
                  <h1 className="text-2xl">Title</h1>
                  <Forms<IEmbedTitle, IEmbed> state={title} partName={"title"}
                                       onStateChange={onTitleChange}
                                       fieldMetaData={{value: titleValueFieldProps, titleURL: titleURLFieldProps}}
                                       outSideSubmit={outSideSubmit}/>

              </ListGroupItem>
              <ListGroupItem>
                  <h1 className="text-2xl">Basic data</h1>
                  <Forms<IEmbedBasicData, IEmbed> state={basicData} partName={"basicData"}
                                          onStateChange={onBasicDataChange}
                                          fieldMetaData={{content: basicDataContentFieldProps,color: basicDataColorFieldProps , description: basicDataDescriptionFieldProps, imageURL: basicDataImageURLFieldProps, thumbnailURL: basicDataThumbnailURLFieldProps}}
                                          outSideSubmit={outSideSubmit}
                  />
              </ListGroupItem>
              <ListGroupItem>
                  <h1 className="text-2xl">Fields data</h1>
                  <FormList<IEmbedField, IEmbed> draggable={true}
                                         partName={"fields"}
                                         onFormChange={onFieldsChange}
                                         onValidChange={updateValidate}
                                         fieldMetaData={{name: fieldNameFieldProps, value: fieldValueFieldProps, inline: fieldInlineFieldProps}}
                                         defaultElement={defaultFieldValueElement}
                                         states={fields}
                                         submit={submit}
                                         elementLimit={25} />
              </ListGroupItem>
              <ListGroupItem>
                  <h1 className="text-2xl">Footer data</h1>
                  <Forms<IEmbedFooter, IEmbed> partName={"footer"}
                                       fieldMetaData={{value: footerValueFieldProps, iconURL: footerIconURLFieldProps, timeStamp: footerTimeStampProps}}
                                       state={footer}
                                       onStateChange={onFooterChange}
                                       outSideSubmit={outSideSubmit} />
              </ListGroupItem>
              <div className="flex justify-around">
                  <Button onClick={resetChanges} variant="danger" className="m-2">
                      Cancel
                  </Button>
                  {submit < 1 || !allFieldsValid() ? (
                      <Button
                          disabled={hasChanges()}
                          className="m-2"
                          onClick={handleCheckPress}
                      >
                          check
                      </Button>
                  ) : (
                      <Button
                          disabled={hasChanges()}
                          type="submit"
                          className="m-2"
                          onClick={handleSubmitPress}
                      >
                          submit
                      </Button>
                  )}
              </div>
          </ListGroup>
      </>
  )
}
/*
    const onAuthorChange = (value: FormStateOnValueChangeTypeof, name: keyof IEmbedAuthor) => {
        setAuthor({...author, [name]: value});
    }

    const onBasicDataChange = (value: FormStateOnValueChangeTypeof, name: keyof IEmbedBasicData) => {
        setBasicData({...basicData, [name]: value});
    }

    const onFieldsChange = (fields: StatePre<IEmbedField>[]) => {
        setFields([...fields]);
    }

    const onFooterChange = (value: FormStateOnValueChangeTypeof, name: keyof IEmbedFooter) => {
        setFooter({...footer, [name]: value});
    }*/
