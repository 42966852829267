import { IdToken } from "@auth0/auth0-react";

export const useAuthTokens = () => {
  let token = localStorage.getItem("access_token");
  let dcToken = localStorage.getItem("discord_access_token");
  let idToken = localStorage.getItem("id_token");
  let refreshToken = localStorage.getItem("refresh_token");

  const removeAll = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("discord_access_token");
  };
  const updateTokens = (
    token: string | undefined,
    idToken: IdToken | undefined,
    refreshToken: string | undefined,
    dcToken: string | undefined
  ) => {
    token && localStorage.setItem("access_token", token);
    idToken &&
      idToken.org_id &&
      localStorage.setItem("id_token", idToken.org_id);
    refreshToken && localStorage.setItem("refresh_token", refreshToken);
    dcToken && localStorage.setItem("discord_access_token", dcToken);
  };

  return {
    dcToken,
    token,
    idToken,
    refreshToken,
    updateTokens,
    removeAll,
  };
};
