import { useAuth0 } from "@auth0/auth0-react";
import { useTranslate } from "@tolgee/react";
import React, { useEffect } from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Button} from "primereact/button";

const Home = () => {
  const t = useTranslate();

  return (
    <>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#a2d9ff" fill-opacity="1" d="M0,256L16,229.3C32,203,64,149,96,122.7C128,96,160,96,192,133.3C224,171,256,245,288,250.7C320,256,352,192,384,144C416,96,448,64,480,48C512,32,544,32,576,74.7C608,117,640,203,672,197.3C704,192,736,96,768,53.3C800,11,832,21,864,58.7C896,96,928,160,960,192C992,224,1024,224,1056,229.3C1088,235,1120,245,1152,224C1184,203,1216,149,1248,160C1280,171,1312,245,1344,250.7C1376,256,1408,192,1424,160L1440,128L1440,0L1424,0C1408,0,1376,0,1344,0C1312,0,1280,0,1248,0C1216,0,1184,0,1152,0C1120,0,1088,0,1056,0C1024,0,992,0,960,0C928,0,896,0,864,0C832,0,800,0,768,0C736,0,704,0,672,0C640,0,608,0,576,0C544,0,512,0,480,0C448,0,416,0,384,0C352,0,320,0,288,0C256,0,224,0,192,0C160,0,128,0,96,0C64,0,32,0,16,0L0,0Z"></path></svg>
        <Container>
            <Row>
                <Col sm={8}>
                    <Row className="flex flex-row">
                        <Col>
                            <h1 className="text-7xl">Multi Utility Bot</h1>
                        </Col>
                        <Col sm={4} className="flex items-center">
                            <Button >Add to Server</Button>
                        </Col>
                    </Row>
                    <p className="text-2xl">Create you Custom formulare, embeds and other stuff and setup your protection</p>
                    <p className="text-2xl">Subscribe to your favorite influence on Youtube, Twitch and other Social media pages to</p>
                    <p className="text-2xl">Stay update and receive notifications on new post or live streams</p>
                </Col>
                <Col sm={4}>
                    <div className="flex align-self justify-around">
                        <img alt={"LEpEX utility bot avatar"} src="https://cdn.discordapp.com/icons/820309060829839401/0060dd2e1243a81ee222961508a99be9?size=1024"/>
                    </div>
                </Col>
            </Row>

        </Container>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path fill="#a2d9ff" fill-opacity="1" d="M0,192L24,181.3C48,171,96,149,144,133.3C192,117,240,107,288,96C336,85,384,75,432,101.3C480,128,528,192,576,202.7C624,213,672,171,720,170.7C768,171,816,213,864,192C912,171,960,85,1008,80C1056,75,1104,149,1152,202.7C1200,256,1248,288,1296,272C1344,256,1392,192,1416,160L1440,128L1440,320L1416,320C1392,320,1344,320,1296,320C1248,320,1200,320,1152,320C1104,320,1056,320,1008,320C960,320,912,320,864,320C816,320,768,320,720,320C672,320,624,320,576,320C528,320,480,320,432,320C384,320,336,320,288,320C240,320,192,320,144,320C96,320,48,320,24,320L0,320Z"></path>
        </svg>
    </>
  );
};

export default Home;
