import React, {useEffect, useState} from 'react'
import {useApiRequest} from "../../../hooks/useApiRequest";
import {ApiSelectInputFieldProps, StatePre} from "../FormTypes";
import {useFormsContext} from "../Forms";
import {Col, FloatingLabel, Form, FormControl} from "react-bootstrap";
import {useTranslate} from "@tolgee/react";
import {ISocialChannel} from "../../../api/types/Notification";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {Avatar} from "primereact/avatar";

//K type parameter
//name K key mapper

export const ApiSelectField = <T,>({inputFieldType, name, selectOptionsUrl="", label, inlineSize, searchKey, placeholder, keyMap}:ApiSelectInputFieldProps<T>) => {
  const {executeRequest, loading, error} = useApiRequest<ISocialChannel[]>("data", selectOptionsUrl, "GET", false);
  const { data: formData, errorState, onBlur, onValueChange, updateAll } = useFormsContext<T>();
  const t = useTranslate();

  const fieldError = errorState[name];
  const [channels, setChannels] = useState<ISocialChannel[]>([]);


    const [searchValue, setSearchValue] = useState("");
    const [selectedChannel, setSelectedChannel] = useState<any>({name: "select channel", channelId: "0", iconUrl:"0"});

    useEffect(() => {
        if(keyMap && searchKey) {
            console.log("nice")
            let tempState:StatePre<T> = formData;
            keyMap?.forEach((e) => {tempState = {...tempState, [e.destinationKey]: selectedChannel[e.sourceKey]}});
            updateAll(tempState);
        }
    }, [selectedChannel]);

    if(!keyMap || !searchKey) {
        return <></>;
    }



    const value = formData[searchKey];
    console.log("searchvalue: ", formData);
    const onSearch =  async () => {
        if(searchValue.length <= 100) {
            const {body} = await executeRequest(undefined, {name: searchValue});
            onSelect(body[0]);
            setChannels([...body]);
        }
    }

    const resetSearch = () => {
        setChannels([]);
    }

    const onSelect = (counter: ISocialChannel) => {
        setSelectedChannel({...counter});
    }

    const countryOptionTemplate = (option: ISocialChannel) => {
        return (
            <div className="flex  flex-row" onClick={() => {onSelect(option)}}>
                <Avatar image={option.iconUrl} />
                <p className="ml-1 mb-0">{option.name}</p>
            </div>
        );
    }



  return (
      <Col sm={inlineSize} className="my-1">
          {selectedChannel && channels.length > 0 ? (
                 <div className="flex items-center">
                        <Dropdown className="w-full"
                                  value={selectedChannel}
                                  options={channels}
                                  onChange={(e) => {onSelect(e.target.value)}}
                                  optionLabel="name"
                                  valueTemplate={countryOptionTemplate}
                                  itemTemplate={countryOptionTemplate}

                        />
                        <i onClick={async (e) => {resetSearch()}} className="pi pi-times ml-2" style={{'fontSize': '1em', 'cursor': 'pointer'}}/>
                 </div>

          ):(
              <>
                  <span className="p-input-icon-right w-full">
                      <i onClick={async (e) => {await onSearch()}} className="pi pi-search" />
                      <InputText readOnly={loading}
                                 defaultValue={value.toString()}
                                 className="w-full"
                                 value={searchValue}
                                 onKeyDown={async (e) => {if(e.code === "Enter") {await onSearch()}}}
                                 onChange={(e) => setSearchValue(e.target.value)}
                                 placeholder={placeholder}
                      />
                  </span>
              </>
          )}
          <Form.Control.Feedback type="invalid">
            {fieldError ? <>{fieldError.message}</> : <>missing error</>}
          </Form.Control.Feedback>
          <Form.Control.Feedback>{t("LOOKS_GOOD_MSG")}</Form.Control.Feedback>
      </Col>
  )
}

