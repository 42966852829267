import {
  FormStateOnValueChangeTypeof,
  HandValidationResponse,
  ValidationOptions,
} from "../comp/form/FormTypes";

export const selectNotDefaultHandler = (
  value: FormStateOnValueChangeTypeof,
  validationOptions?: ValidationOptions
) => {
  let validationResponse: HandValidationResponse = {
    status: "success",
    message: "every thing fine",
  };
  if (typeof value === "number") {
    console.log("nicereis")
    if (value === 0) {
      validationResponse = {
        status: "error",
        message: "you have to select something",
      };
    }
  } else if(typeof value === "string") {
    console.log("nice")
    if (value === "0") {
      validationResponse = {
        status: "error",
        message: "you have to select something",
      };
    }
  }
  return validationResponse;
};
