import {ApiSelectInputFieldProps, KeyMapper, SelectInputFieldProps} from "../../comp/form/FormTypes";
import {CreateNotification, INotification, ISocialChannel} from "../../api/types/Notification";
import {selectNotDefaultHandler} from "../../validationHandler/SelectNotDefaultHandler";

export const textChannelFieldProps: SelectInputFieldProps<INotification> = {
    inputFieldType: "select",
    name: "textChannelId",
    label: "Text channel",
    selectDescribe: "select the inbox Text channel",
    inline: false,
    //not working because it almost covered by onblur
    validationOnSubmit: { selectNotDefault: selectNotDefaultHandler },
    validationOnChange: { selectNotDefault: selectNotDefaultHandler },
};

export const embedIdFieldProps: SelectInputFieldProps<INotification> = {
    inputFieldType: "select",
    name: "embedId",
    label: "embed",
    selectDescribe: "select the inbox Text channel",
    inline: false,
    //not working because it almost covered by onblur
    validationOnSubmit: { selectNotDefault: selectNotDefaultHandler },
    validationOnChange: { selectNotDefault: selectNotDefaultHandler },
};

export const socialKeyMap: KeyMapper<INotification, ISocialChannel>[] = [{destinationKey: "identifier", sourceKey:"channelId"}, {destinationKey: "displayName", sourceKey: "name"}];

export const identifierFieldProps: ApiSelectInputFieldProps<INotification> = {
    inputFieldType: "apiSelect",
    placeholder: "Search channel",
    name: "identifier",
    label: "channel",
    inline: false,
    keyMap: socialKeyMap,
}