import {ApiSelectInputFieldProps, SelectInputFieldProps, BasicInputFieldProps} from "../../comp/form/FormTypes";
import {IEmbedAuthor, IEmbedBasicData, IEmbedField, IEmbedFooter, IEmbedName, IEmbedTitle} from "../../api/types/IEmbed";
import {outOfRangeValidator} from "../../validationHandler/OutOfRangeValidator";
import {fieldNotEmptyValidator} from "../../validationHandler/NotEmptyValidator";
import {urlValidator} from "../../validationHandler/UrlValidator";

export const nameValueFieldProps: BasicInputFieldProps<IEmbedName> =
    {
            inputFieldType: "string",
            name: "value",
            label: "Embed name: ",
            placeholder: "LEpEX About Me",
            inline: false,
            validationOptions: {minMaxValidation: {min: 0, max: 256}},
            validationOnBlur: {minMaxValidation: outOfRangeValidator, notEmptyValidation: fieldNotEmptyValidator},
            validationOnSubmit: {minMaxValidation: outOfRangeValidator, notEmptyValidation: fieldNotEmptyValidator}
    };

export const authorValueFieldProps: BasicInputFieldProps<IEmbedAuthor> =
    {
        inputFieldType: "string",
        name: "value",
        label: "Author",
        placeholder: "LEpEX",
        inline: false,
        validationOptions: {minMaxValidation: {min: 0, max: 256}},
        validationOnBlur: {minMaxValidation: outOfRangeValidator},
        validationOnSubmit: {minMaxValidation: outOfRangeValidator}
    };

export const authorURLFieldProps: BasicInputFieldProps<IEmbedAuthor> =
    {
        inputFieldType: "string",
        name: "valueURL",
        label: "Url",
        placeholder: "https://www.lighthouseproject.org.uk/activities/employment/cv-tips-and-templates/cv-personal-profile-examples/#:~:text=I%20am%20an%20enthusiastic%2C%20self,and%20adhere%20to%20strict%20deadlines.",
        inline: false,
        validationOptions: {minMaxValidation: {min: 0, max: 2000}},
        validationOnBlur: {minMaxValidation: outOfRangeValidator, urlValidator: urlValidator},
        validationOnSubmit: {minMaxValidation: outOfRangeValidator, urlValidator: urlValidator}
    };

export const authorIconURLFieldProps: BasicInputFieldProps<IEmbedAuthor> =
    {
        inputFieldType: "string",
        name: "iconURL",
        label: "Author icon",
        placeholder: "https://i.imgur.com/oBPXx0D.png",
        inline: true,
        validationOptions: {minMaxValidation: {min: 0, max: 2000}},
        validationOnBlur: {minMaxValidation: outOfRangeValidator, urlValidator: urlValidator},
        validationOnSubmit: {minMaxValidation: outOfRangeValidator, urlValidator: urlValidator}
    };

export const titleValueFieldProps: BasicInputFieldProps<IEmbedTitle> =
    {
        inputFieldType: "string",
        name: "value",
        label: "Title",
        placeholder: "Nice EmbedMessage",
        inline: false,
        validationOptions: {minMaxValidation: {min: 0, max: 256}},
        validationOnBlur: {minMaxValidation: outOfRangeValidator},
            validationOnSubmit: {minMaxValidation: outOfRangeValidator}
    };

export const titleURLFieldProps: BasicInputFieldProps<IEmbedTitle> =
    {
        inputFieldType: "string",
        name: "titleURL",
        label: "Url",
        placeholder: "https://www.lighthouseproject.org.uk/activities/employment/cv-tips-and-templates/cv-personal-profile-examples/#:~:text=I%20am%20an%20enthusiastic%2C%20self,and%20adhere%20to%20strict%20deadlines.",
        inline: false,
        validationOptions: {minMaxValidation: {min: 0, max: 2000}},
        validationOnBlur: {minMaxValidation: outOfRangeValidator, urlValidator: urlValidator},
        validationOnSubmit: {minMaxValidation: outOfRangeValidator, urlValidator: urlValidator}
    };

export const basicDataContentFieldProps: BasicInputFieldProps<IEmbedBasicData> =
    {
        inputFieldType: "string",
        name: "content",
        label: "text content",
        placeholder: "now you gonna see an embed message: ",
        inline: false,
    };


export const basicDataColorFieldProps: BasicInputFieldProps<IEmbedBasicData> =
    {
        inputFieldType: "color",
        name: "color",
        label: "Color: ",
        inline: false,
    };


export const basicDataDescriptionFieldProps: BasicInputFieldProps<IEmbedBasicData> =
    {
        inputFieldType: "string",
        name: "description",
        label: "Description",
        placeholder: "this is a cool description",
        inline: false,
        validationOptions: {minMaxValidation: {min: 0, max: 4096}},
        validationOnBlur: {minMaxValidation: outOfRangeValidator},
        validationOnSubmit: {minMaxValidation: outOfRangeValidator, notEmptyValidation: fieldNotEmptyValidator}
    };

export const basicDataThumbnailURLFieldProps: BasicInputFieldProps<IEmbedBasicData> =
    {
        inputFieldType: "string",
        name: "thumbnailURL",
        label: "Thumbnail",
        placeholder: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/Cumulus_Clouds_over_Yellow_Prairie2.jpg/1280px-Cumulus_Clouds_over_Yellow_Prairie2.jpg",
        inline: false,
        validationOptions: {minMaxValidation: {min: 0, max: 2000}},
        validationOnBlur: {minMaxValidation: outOfRangeValidator, urlValidator: urlValidator},
        validationOnSubmit: {minMaxValidation: outOfRangeValidator, urlValidator: urlValidator}
    };

export const basicDataImageURLFieldProps: BasicInputFieldProps<IEmbedBasicData> =
    {
        inputFieldType: "string",
        name: "imageURL",
        label: "Image",
        placeholder: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/Cumulus_Clouds_over_Yellow_Prairie2.jpg/1280px-Cumulus_Clouds_over_Yellow_Prairie2.jpg",
        inline: false,
        validationOptions: {minMaxValidation: {min: 0, max: 2000}},
        validationOnBlur: {minMaxValidation: outOfRangeValidator, urlValidator: urlValidator},
            validationOnSubmit: {minMaxValidation: outOfRangeValidator, urlValidator: urlValidator}
    };

export const fieldNameFieldProps: BasicInputFieldProps<IEmbedField> =
    {
        inputFieldType: "string",
        name: "name",
        label: "Field name",
        placeholder: "cool field",
        inline: false,
        validationOptions: {minMaxValidation: {min: 0, max: 256}},
        validationOnBlur: {minMaxValidation: outOfRangeValidator},
            validationOnSubmit: {minMaxValidation: outOfRangeValidator}
    };

export const fieldValueFieldProps: BasicInputFieldProps<IEmbedField> =
    {
        inputFieldType: "string",
        name: "value",
        label: "Field value",
        placeholder: "cool value",
        inline: false,
        validationOptions: {minMaxValidation: {min: 0, max: 1024}},
        validationOnBlur: {minMaxValidation: outOfRangeValidator},
        validationOnSubmit: {minMaxValidation: outOfRangeValidator}
    };

export const fieldInlineFieldProps: SelectInputFieldProps<IEmbedField> =
    {
        inputFieldType: "switch",
        name: "inline",
        label: "Inline",
        placeholder: "true",
        inline: false,
        isSwitch: true,
    };

export const footerValueFieldProps: BasicInputFieldProps<IEmbedFooter> =
    {
        inputFieldType: "string",
        name: "value",
        label: "Footer value",
        placeholder: "i am a footer",
        inline: false,
        validationOptions: {minMaxValidation: {min: 0, max: 2048}},
        validationOnBlur: {minMaxValidation: outOfRangeValidator},
            validationOnSubmit: {minMaxValidation: outOfRangeValidator}
    };

export const footerIconURLFieldProps: BasicInputFieldProps<IEmbedFooter> =
    {
        inputFieldType: "string",
        name: "iconURL",
        label: "Icon URL",
        placeholder: "i am a footer",
        inline: false,
        validationOptions: {minMaxValidation: {min: 0, max: 2000}},
        validationOnBlur: {minMaxValidation: outOfRangeValidator, urlValidator: urlValidator},
            validationOnSubmit: {minMaxValidation: outOfRangeValidator, urlValidator: urlValidator}
    };

export const footerTimeStampProps: SelectInputFieldProps<IEmbedFooter> =
    {
        inputFieldType: "switch",
        name: "timeStamp",
        label: "Timestamp",
        placeholder: "true",
        inline: false,
        isSwitch: true,
    };