import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { once } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { App } from "./App";
import { AppLocationWrapperProps } from "./BaseApp";
import DataProvider from "./contextProvider/ModuleProvider";
import { useApiRequest } from "./hooks/useApiRequest";
import { useAuthTokens } from "./hooks/useAuth";
import { IGuild } from "./models/IDiscord";
import Loading from "./sites/loading/Loading";
import { getDiscordAccessToken } from "./utils/DiscordRequest";
import {Container} from "react-bootstrap";

const createGuildsContext = once(() =>
  React.createContext({} as GuildsContext)
);
export const useGuildsContext = () => useContext(createGuildsContext());

export interface GuildsContext {
  guilds?: IGuild[];
  refreshGuilds: () => void;
  getActiveGuild: (id: string | undefined) => IGuild | undefined;
}

const AuthLayer = ({ children }: AppLocationWrapperProps) => {
  const auth0 = useAuth0();
  const tokens = useAuthTokens();
  const [isFinished, setIsFinished] = useState(false);
  /**
  const refreshGuilds = async () => {
    await executeRequest(undefined);
  }; */

  useEffect(() => {
    initTokens();
  }, []);

  const initTokens = async () => {
    const access_token = await auth0.getAccessTokenSilently();

    const discordData = await getDiscordAccessToken(access_token);
    tokens.updateTokens(
      access_token,
      undefined,
      undefined,
      discordData.access_token
    );
    setIsFinished(true);
  };

  const GuildsContext = createGuildsContext();

  const { data, executeRequest, error } = useApiRequest<IGuild[]>(
    "data",
    "discord/user/guilds",
    "GET"
  );

  const refreshGuilds = () => {
    executeRequest(undefined);
  };

  const getActiveGuild = (id: string | undefined) => {
    if (id) {
      return data?.body?.filter(
        (e) => hasPermissions(e) && String(e.id) === id
      )[0];
    }
  };

  const hasPermissions = (checkGuild: IGuild) => {
    return (
      checkGuild.owner ||
      (checkGuild?.permissions & 0x8) == 0x8 ||
      (checkGuild?.permissions & 0x2) == 0x2
    );
  };

  return (
    <>

        {isFinished && auth0.isAuthenticated ? (
            <>
              <GuildsContext.Provider
                  value={{
                    guilds: data?.body?.filter((e) => hasPermissions(e)),
                    refreshGuilds: refreshGuilds,
                    getActiveGuild: getActiveGuild,
                  }}
              >
                <DataProvider>{children}</DataProvider>
              </GuildsContext.Provider>
            </>
        ) : (
            <>
              <Loading></Loading>
            </>
        )}
    

    </>
  );
};

export default withAuthenticationRequired(AuthLayer, {
  onRedirecting: () => <Loading />,
});
