import axios from "axios";
import { Identitie } from "../api/types/Identitie";
import { IGuild } from "../models/IDiscord";

export async function getDiscordAccessToken(token: string) {
  const res = await axios.get(
    process.env.REACT_APP_API_BASE_URL + "api/v1/auth/Discord/token",
    {
      headers: {
        "Access-Control-Allow-Origin": "http://localhost:3000",
        Authorization: "Bearer " + token,
      },
    }
  );
  const data: Identitie = JSON.parse(JSON.stringify(res.data));

  return data;
}

/*
export async function getDiscordGuilds(token: string) {
  const res = await axios.get("https://discord.com/api/v8/users/@me/guilds", {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  const data: IGuild[] = JSON.parse(JSON.stringify(res.data));
  return data.filter(hasPermissions);
}*/

export async function getGuildDetails(userId: string, token: string) {
  const res = await axios.get(
    "https://lepex.eu.auth0.com/api/v2/users/" + userId,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return res.data;
}
