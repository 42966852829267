import { T } from "@tolgee/react";

import {keysIn, once} from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Button, Form, FormProps } from "react-bootstrap";
import { validate } from "uuid";
import { FormRender } from "./FormRender";
import {
  FieldPropsMapper,
  FormStateOnValueChangeTypeof,
  HandValidationResponse, StatePre,
  ValidationMethods,
  ValidationMethodsMapper,
} from "./FormTypes";

export interface OutSideSubmit<K={}> {
  triggerSubmit: number;
  setValid: (value: boolean, name?: keyof K) => void;
}

export type ErrorState<T> = {
  [key in keyof T]?: HandValidationResponse;
};

export interface Expandable {
  isExpandable: boolean;
}

export interface FormElementProp {
  draggable: boolean;
  expandable?: Expandable;
}

export type FormElementProps<T> = {
  [key in keyof T]: FormElementProp;
};

export interface IFormProps<T, K={}> {
  partName?: keyof K,
  fieldMetaData: FieldPropsMapper<T>;
  isModal?: boolean;
  onSubmitValid?: () => void;
  state: StatePre<T>;
  onStateChange: (data: StatePre<T>, name?: keyof T) => void;
  outSideSubmit?: OutSideSubmit<K>;
}

export interface IFormProvider<T> {
  data: StatePre<T>;
  errorState: ErrorState<T>;
  onValueChange: (name: keyof T, value: FormStateOnValueChangeTypeof) => void;
  onBlur: (name: keyof T, value: FormStateOnValueChangeTypeof) => void;
  updateAll: (data: StatePre<T>) => void;
}

const createFormsContext = once(<T,>() =>
  React.createContext({} as IFormProvider<T>)
);
export const useFormsContext = <T,>() => useContext(createFormsContext<T>());

export const Forms = <T, K = {}>({
  partName,
  state,
  onStateChange,
  onSubmitValid,
  fieldMetaData,
  outSideSubmit,

}: IFormProps<T, K>) => {
  //console.log("local form Component rendered", state);

  const FormContext = createFormsContext<T>();
  const [localState, setLocalState] = useState<StatePre<T>>({...state});
  const [localValid, setLocalValid] = useState<boolean>(false);
  const [errorState, setErrorState] = useState<ErrorState<T>>({});
  useEffect(() => {
  console.log("localState change: ", state);
      setLocalState(state);
    }, [state]);



  const updateAllStates = (name: keyof T, value: FormStateOnValueChangeTypeof) => {
    console.log(name, "data: ", value)
    setLocalState({...localState, [name]: value});
    onStateChange({...localState, [name]: value}, name);
  }

  /**/
  useEffect(() => {
    const testRunner = async () => {
      await initSubmit();
    };
    if (outSideSubmit && outSideSubmit?.triggerSubmit > 0) {
      testRunner();
    }
  }, [outSideSubmit?.triggerSubmit]);

  useEffect(() => {
    return () => {
      console.log("data: ", localState);
    };
  }, [localState]);


  const initSubmit = async () => {
    const formValid = await onSubmitValidation();
    updateValid(formValid);
    if (formValid && onSubmitValid) {
      onSubmitValid();
    }
  };

  //iterate over updateErrorOnSubmit error submit return boolean if is valid;
  const onSubmitValidation = async () => {
    const fieldKeys = Object.keys(fieldMetaData) as Array<keyof T>;
    let finalValid = true;
    let tempErrorState: ErrorState<T> = {};

    fieldKeys.forEach((key) => {
      switch (fieldMetaData[key]?.inputFieldType) {
        case "string":
          tempErrorState[key] = updateErrorOnSubmit(key, String(localState[key]));
          break;
        case "number":
        case "select":
          tempErrorState[key] = updateErrorOnSubmit(key, Number(localState[key]));
          break;
        case "switch":
          tempErrorState[key] = updateErrorOnSubmit(key, Boolean(localState[key]));
          break;
        default:
          console.log("unknown type");
      }
      if (tempErrorState[key]?.status === "error") {
        finalValid = false;
      }
    });

    setErrorState({ ...tempErrorState });

    return finalValid;
  };

  const updateValid = (value: boolean) => {
    if (outSideSubmit) {
      if(partName) {
        outSideSubmit.setValid(value, partName);
      } else {
        outSideSubmit.setValid(value);
      }
      setLocalValid(value);
    } else {
      setLocalValid(value);
    }
  };

  const updateAll = (data: StatePre<T>) => {
    //onStateChange(data);
    setLocalState({...data});

  };

  const updateErrorOnSubmit = (
    name: keyof T,
    value: FormStateOnValueChangeTypeof
  ) => {
    const fieldData = fieldMetaData[name];
    let defaultValidationResponse: HandValidationResponse = {
      status: "success",
      message: "everything fine",
    };
    if (fieldData) {
      const submitValidations = fieldData.validationOnSubmit;
      if (submitValidations) {
        const validationResponse = runValidation(
          fieldData.validationOptions,
          submitValidations,
          value
        );
        //console.log("after validation run on submit", validationResponse);
        if (validationResponse.status === "error") {
          updateValid(false);
        }
        return validationResponse;
      } else {
        return errorState[name];
      }
    }
    return defaultValidationResponse;
  };

  const updateErrorOnBlur = async (
    name: keyof T,
    value: FormStateOnValueChangeTypeof
  ) => {
    //console.log("onBlurValidation: " + value?.toString().length);
    const fieldData = fieldMetaData[name];
    if (fieldData) {
      const blurFunctions = fieldData.validationOnBlur;
      if (blurFunctions) {
        const validationResponse = runValidation(
          fieldData.validationOptions,
          blurFunctions,
          value
        );
        console.log("validatin response: ", validationResponse);

        updateError(name, validationResponse);
      }
    }
  };

  const updateErrorOnChange = async (
    name: keyof T,
    value: FormStateOnValueChangeTypeof
  ) => {
    const fieldData = fieldMetaData[name];
    if (fieldData) {
      const validationFunction = fieldData.validationOnChange;
      if (validationFunction) {
        const validationResponse = runValidation(
          fieldData.validationOptions,
          validationFunction,
          value
        );
        updateError(name, validationResponse);
      }
    }
  };

  const updateError = (name: keyof T, validationResponse: HandValidationResponse) => {
    if (validationResponse.status === "error") {
      updateValid(false);
    }
    setErrorState({
      ...errorState,
      [name]: validationResponse,
    });
  };

  const runValidation = (
    options: ValidationMethodsMapper | undefined,
    validationFunctions: ValidationMethods,
    value: FormStateOnValueChangeTypeof
  ) => {
    const keys = Object.keys(validationFunctions) as Array<
      keyof ValidationMethods
    >;

    let validationResponse: HandValidationResponse = {
      status: "success",
      message: "",
    };

    keys.forEach((e) => {
      //console.log("run valdaiton form: " + e);
      let tempReponse: HandValidationResponse;
      const validationFunction = validationFunctions[e];
      if (validationFunction) {
        if (options) {
          const validationOptin = options[e];
          if (validationOptin) {
            tempReponse = validationFunction(value, validationOptin);
          } else {
            tempReponse = validationFunction(value);
          }
        } else {
          tempReponse = validationFunction(value);
        }
        if (tempReponse.status !== "success") {
          validationResponse = {
            status: "error",
            message: validationResponse.message + " " + tempReponse.message,
          };
        }
      }
    });
    return validationResponse;
  };

  useEffect(() => {
    //console.log("errorstate update: ", errorState);
  }, [errorState]);
  useEffect(() => {
    console.log("state update: ", state);
  }, [state]);

  const onBlur = async (name: keyof T, value: FormStateOnValueChangeTypeof) => {
    await updateErrorOnBlur(name, value);
  };

  const onChange = async (name: keyof T, value: FormStateOnValueChangeTypeof) => {
    updateAllStates(name, value);
    await updateErrorOnChange(name, value);
  };

  //validation mapper
  //metadata mappper

  return (
    <FormContext.Provider
      value={{
        data: localState,
        errorState: errorState,
        onValueChange: onChange,
        updateAll: updateAll,
        onBlur: onBlur,
      }}
    >
      <Form onSubmit={(e) => {e.preventDefault();}}>
        <FormRender<T> fieldsMetaData={fieldMetaData} />
        {!outSideSubmit && (
          <div className="flex flex-row justify-around">
            <Button>Cancel</Button>
            <Button
              onClick={async () => {
                initSubmit();
              }}
            >
              Submit
            </Button>
          </div>
        )}
      </Form>
    </FormContext.Provider>
  );
};
