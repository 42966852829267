import {
  AppState,
  Auth0Provider,
  Auth0ProviderOptions,
  useAuth0,
  withAuthenticationRequired,
} from "@auth0/auth0-react";
import React, { createContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getConfig } from "./config";
import { ApiInstanceProvider, ContextValue } from "./api/ApiInstanceProvider";
import MyNavbar from "./comp/navbar/MyNavbar";
import { Container } from "react-bootstrap";
export const AxiosContext = createContext<ContextValue>(undefined);
export interface AppLocationWrapperProps {
  children: React.ReactElement | React.ReactNode;
}
export const BaseApp = ({ children }: AppLocationWrapperProps) => {
  const nav = useNavigate();

  const RedirectCallback = async (appState: AppState | undefined) => {
    nav("/guilds");
  };
  const config = getConfig();
  const providerConfig: Auth0ProviderOptions = {
    scope: "user_idp_tokens, users",
    connection: "Discord",
    domain: config.domain,
    clientId: config.clientId,
    ...(config.audience ? { audience: config.audience } : null),
    redirectUri: window.location.origin,
    useRefreshTokens: true,
    onRedirectCallback: RedirectCallback,
  };

  return (
    <Auth0Provider {...providerConfig}>
      <ApiInstanceProvider requestInterceptors={[]} responseInterceptors={[]}>
        <MyNavbar />
        <div className="">{children}</div>

      </ApiInstanceProvider>
    </Auth0Provider>
  );
};
