import { useEffect, useState } from "react";
import { useGuildsContext } from "../AuthLayer";

/**
 * Set the indicator when the app should be loaded (with time buffer of 2 seconds)
 */
export const useAppStartLoading = () => {
  const [appLoading, setAppLoading] = useState(true);
  const { guilds } = useGuildsContext();
  useEffect(() => {
    if (guilds) setAppLoading(false);
  }, [guilds]);

  return {
    appLoading,
  };
};
