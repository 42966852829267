import React, {useState} from 'react'
import {ColorPicker} from "primereact/colorpicker";
import {BasicInputFieldProps} from "../FormTypes";
import {T, useTranslate} from "@tolgee/react";
import {useFormsContext} from "../Forms";
import {Col} from "react-bootstrap";
import "./editor.css"
export const ColorPickerInputField = <T,>({
                                            inputFieldType,
                                            returnType = "string",
                                            name,
                                            label,
                                            readOnly = false,
                                            optional = false,
                                            inlineSize,
                                            placeholder = "",
                                            tooltip,
                                            validationOptions,
                                            hide = false,
                                            uuid,
                                            validationOnBlur,
                                          }: BasicInputFieldProps<T>) => {
  const { data, errorState, onValueChange, onBlur } = useFormsContext<T>();
  const t = useTranslate();
  const value = data[name];


  return (
        <Col sm={inlineSize}>
              <div className="mb-2 mt-1 ml-1 mr-1 p-2 w-full flex flex-row justify-between align-items-center my-border">
                <label className="ml-1">{label}</label>
                <ColorPicker className="mr-1" value={value.toString()} onChange={(e) => {e.value && (onValueChange(name, e.value.toString()))}}></ColorPicker>
              </div>
        </Col>
  )
}

