import {
  FormStateOnValueChangeTypeof,
  HandValidationResponse,
  ValidationOptions,
} from "../comp/form/FormTypes";

export const outOfRangeValidator = (
  value: FormStateOnValueChangeTypeof,
  validationOptions?: ValidationOptions
) => {
  //console.log(validationOptions);
  let handlerResponse: HandValidationResponse = {
    status: "success",
    message: "EVERYTHING_IS_FINE",
  };

  if (typeof value === "string") {
    if (validationOptions) {
      if (
        validationOptions.min &&
        value.length < validationOptions.min
      ) {
        //console.log("validation: min");
        handlerResponse = {
          status: "error",
          message: "FIELD_ERROR_MINLENGTH",
        };
      } else if (
        validationOptions.max &&
        value.length > validationOptions.max
      ) {
        //console.log("validation: max");
        handlerResponse = {
          status: "error",
          message: "FIELD_ERROR_MAXLENGTH",
        };
      }
    }
  } else {
    //console.log("validation: wrong validation");
    handlerResponse = {
      status: "error",
      message: "wrong error handler",
    };
  }

  //console.log("vaidation nothing triggerd");
  //console.log(handlerResponse);
  return handlerResponse;
};
