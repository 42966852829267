import React from "react";
import { IGuild } from "./IDiscord";
import {Card} from "primereact/card";

const GuildCard = (guild: IGuild) => {
  let img = "820309060829839401/0060dd2e1243a81ee222961508a99be9";
  if (guild.icon != null) {
    img = guild.id + "/" + guild.icon;
  }

  const header = (
      <img alt="Card" src={"https://cdn.discordapp.com/icons/" + img + ".png?size=1024"}  />
  );

  return (
    <div>
      <Card title={guild.name} subTitle="Subtitle" style={{ width: '25em' }}  header={header} />
    </div>
  );
};

export default GuildCard;
