import {FormStateOnValueChangeTypeof, HandValidationResponse, ValidationOptions} from "../comp/form/FormTypes";

export const urlValidator = (
    value: FormStateOnValueChangeTypeof,
    validationOptions?: ValidationOptions
) => {
    const regexp = new RegExp('^https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)$');
    let handlerResponse: HandValidationResponse = {
        status: "success",
        message: "EVERYTHING_IS_FINE",
    };
    if(typeof value === "string") {
        if(value.length > 0) {
            if (!regexp.test(value)) {
                handlerResponse = {
                    status: "error",
                    message: "INVALID_URL",
                };
            }
        }
    } else {
        handlerResponse = {status: "error", message: "wrong validator please contact an admin"};
    }


    return handlerResponse;
};