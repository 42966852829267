import { Numbers } from "@mui/icons-material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import {
  FloatingLabel,
  Form,
  Col,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { useFormsContext } from "../Forms";
import { SelectInputFieldProps, BasicInputFieldProps } from "../FormTypes";

export const SelectInputField = <T,>({
  inputFieldType,
  name,
  selectOptions = [
    { name: "NO", value: 0 },
    { name: "YES", value: 1 },
  ],
  label,
  readOnly = false,
  optional = false,
  inlineSize,
  placeholder = "",
  tooltip,
  hide = false,
  selectDescribe,
}: SelectInputFieldProps<T>) => {
  const { data, errorState, onBlur, onValueChange } = useFormsContext<T>();

  const t = useTranslate();
  let fieldError = errorState[name];
  const forceFormat = (value: string) => {
    if (typeof selectOptions[0].value === "number") {
      const copyValue = parseInt(value);
      if (inputFieldType === "select") {
        return copyValue;
      } else if (inputFieldType === "switch") {
        return copyValue === 1;
      }
    } else {
      return value;
    }
    return value;
  };

  return (
    <Col sm={inlineSize} className="my-1">
      <FloatingLabel
        hidden={hide}
        controlId="floatingInput"
        label={t(label)}
        className="mb-1 ml-1 mr-1 flex flex-row w-full"
      >
        <Form.Select
          aria-label="Default select example"
          onChange={(e) => {
            onValueChange(name, forceFormat(e.currentTarget.value));
          }}
          onBlur={async (e) => {
            onBlur(name, forceFormat(e.currentTarget.value));
          }}
          isInvalid={fieldError ? fieldError.status === "error" : false}
          defaultValue={
            typeof data[name] === "string"
              ? String(data[name])
              : Number(data[name])
          }
        >
          {selectDescribe && <option value={0}>{selectDescribe}</option>}
          {selectOptions.map(({ name, value }, index) => {
            return (
              <option key={index} value={value}>
                {name}
              </option>
            );
          })}
          ;
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          {fieldError ? <>{fieldError.message}</> : <>missing error</>}
        </Form.Control.Feedback>
        <Form.Control.Feedback>{t("LOOKS_GOOD_MSG")}</Form.Control.Feedback>
      </FloatingLabel>
    </Col>
  );
};
