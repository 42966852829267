import { withAuthenticationRequired } from "@auth0/auth0-react";
import { once } from "lodash";
import React, { createContext, StrictMode, useContext } from "react";
import { BrowserRouter } from "react-router-dom";
import { ContextValue } from "./api/ApiInstanceProvider";
import AppRouter from "./AppRouter";
import { useApiRequest } from "./hooks/useApiRequest";
import Loading from "./sites/loading/Loading";
import {Dialog} from "primereact/dialog";

//const createUserContext = once(() => React.createContext({} as UserContext));
//export const useUserContext = () => useContext(createUserContext());
export interface UserDTO {}

export interface UserContext {
  user?: UserDTO;
  refreshUser: () => void;
}

export const App = () => {

  return (
    <React.StrictMode>
      <BrowserRouter>
        <AppRouter></AppRouter>
      </BrowserRouter>
    </React.StrictMode>
  );
};
