import { data } from "autoprefixer";

import React, { useState } from "react";

import { Modal, Button } from "react-bootstrap";
import { IFormBasicData } from "../../api/types/Form";
import {Forms, IFormProps, OutSideSubmit} from "./Forms";
import {Dialog} from "primereact/dialog";
import {StatePre} from "./FormTypes";

export interface ModalFormData<T> {
  title: string;
  showModal: boolean;
  handleClose: () => void;
  formProps: IFormProps<T>;

}

export const ModalForm = <T,>({
  title,
  showModal,
  handleClose,
  formProps,
}: ModalFormData<T>) => {
  //onsubmit validate
  //onsubmit setboolean submitted true
  //set useeffect to submitted from outside to fetch the data
    const [submit, setSubmit] = useState(0);
    const [valid, setValidate] = useState(false);
    const updateValid = (valid: boolean) => {
        setValidate(valid);
    }
    const outSideSubmit:OutSideSubmit = {
        triggerSubmit:submit,
        setValid: updateValid
    };

    const handleSubmit = () => {
        setSubmit(submit +1);
    }
    const handleCloseLocal = () => {
        handleClose();
        setSubmit(0);
    }


    const footer = () => {
        return <div>
            <Button variant="secondary" onClick={handleClose}>
                cancel
            </Button>
            <Button type="submit" variant="primary" onClick={handleSubmit}>
                confirm
            </Button>
        </div>
    }


  return (
        <Dialog visible={showModal} header={title} onHide={handleCloseLocal} footer={footer}>
            <div className=":max-w-md md:max-w-7xl">
            <Forms<T> {...formProps} outSideSubmit={outSideSubmit}></Forms>
            </div>
        </Dialog>

  );
};