import {
  FormStateOnValueChangeTypeof,
  HandValidationResponse,
  ValidationOptions,
} from "../comp/form/FormTypes";

export const fieldNotSameNameHandler = (
  value: FormStateOnValueChangeTypeof,
  validationOptions: ValidationOptions
) => {
  //console.log("not same validation: " + value);

  let response: HandValidationResponse = {
    status: "success",
    message: "everything fine",
  };

  if (validationOptions) {
    const stringValues = validationOptions.stringValues;
    const maxRepeateAmount = validationOptions.maxRepeatAmount;
    if (stringValues) {
      if (maxRepeateAmount) {
        if (stringValues.filter((e) => e === value).length > maxRepeateAmount) {
          response = { status: "error", message: "FIELD_NOT_SAME_NAME" };
        }
      } else {
        if (stringValues.filter((e) => e === value).length > 0) {
          response = { status: "error", message: "FIELD_NOT_SAME_NAME" };
        }
      }
    }
  } else {
    return response;
  }
  return response;
};
