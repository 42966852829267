import React, { useEffect, useState } from "react";
import { Button, ListGroup, ListGroupItem } from "react-bootstrap";
import { IForm, IFormBasicData } from "../../api/types/Form";
import { IFormElement } from "../../api/types/FormElement";
import { Forms, OutSideSubmit } from "../../comp/form/Forms";
import { isEqual } from "lodash";

import { useApiRequest } from "../../hooks/useApiRequest";
import {
  MultiSelectOptions,
  SelectInputFieldProps, StatePre,
  BasicInputFieldProps,
} from "../../comp/form/FormTypes";
import { useDataContext } from "../../contextProvider/ModuleProvider";
import { selectNotDefaultHandler } from "../../validationHandler/SelectNotDefaultHandler";
import ConfirmModal from "../../comp/confirm/ConfirmModal";
import {FormList} from "../../comp/form/FormList";
import {v4 as uuid} from "uuid";
import {
  allowRoleFieldProps,
  anonymousFieldProps, customIdFieldProps,
  fieldnameFieldPropsTemplate, inboxFieldProps, linkChannelIdFieldProps, linkMessageChannelIdFieldProps,
  maxFieldPropsTemplate,
  minFieldPropsTemplate, placeholderFieldPropsTemplate,
  requiredFieldPropsTemplate,
  styleFieldPropsTemplate
} from "./FormBuilderProps";

export interface SelectOptions {
  name: string;
  value: number | string;
}

export interface IFormViewer {
  form: IForm;
  discordChannels: SelectOptions[];
  allowRoles: MultiSelectOptions[];
}

export const FormViewer = ({ discordChannels, form, allowRoles }: IFormViewer) => {

  //console.log("Form viewer rendered", form.basicFormData.customId);
  const [showDelete, setShowDelete] = useState(false);
  const [submit, setSubmit] = useState(0);

  const { refresh: refreshFormData } = useDataContext().forms;
  const { executeRequest, data, error } = useApiRequest<IForm>(
    "data",
    "form/:guildId/update",
    "POST",
    false
  );
  const { executeRequest: executeDelete } = useApiRequest<IForm>(
    "data",
    "form/:guildId/delete/:customId",
    "DELETE",
    false
  );


  const [localFormBasicData, setLocalFormBasicData] = useState<StatePre<IFormBasicData>>(form.basicFormData);
  const [localFormEntriesData, setLocalFormEntriesData] = useState<StatePre<IFormElement>[]>(form.entries);

  const onFormBasicDataChange = (basic: StatePre<IFormBasicData>) => {
    setLocalFormBasicData({ ...basic });
  };

  const onFormEntriesChange = (entries: StatePre<IFormElement>[]) => {
    setLocalFormEntriesData([...entries]);
  };

  const [validBasicData, setValidBasicData] = useState(false);
  const [validEntriesData, setValidEntriesData] = useState(false);

  useEffect(() => {
      console.log(noChanges());
      console.log("data under edit basic data: ", localFormEntriesData);
      console.log("base data: ", form.entries)

  }, [localFormEntriesData, form.entries, localFormBasicData]);


  const onDataEntriesValidChang = (value: boolean) => {
    console.log("value data valid change: ", value);
    setValidEntriesData(value);
  };


  const handleCheckPress = () => {
    setSubmit(submit + 1);
  };


  const handleSubmitPress = async () => {
    if(localFormBasicData.guildId && (typeof localFormBasicData.guildId === "string" || typeof localFormBasicData.guildId === "number")) {
      await executeRequest(
          {
            uuid: form.id,
            basicFormData: localFormBasicData,
            entries: localFormEntriesData,
            order: ""
          },
          { guildId: localFormBasicData.guildId }
      );
      refreshFormData();
    }
  };

  const basicDataOutSideSubmit:OutSideSubmit = {
    triggerSubmit: submit,
    setValid: setValidBasicData
  }

  const noChanges = () => {
    return isEqual(form.entries, localFormEntriesData) && isEqual(form.basicFormData, localFormBasicData);
  };


  const deleteConfirm = async () => {
    await executeDelete(undefined, {
      guildId: form.basicFormData.guildId,
      customId: form.basicFormData.customId,
    });
    refreshFormData();
  };

  const deleteCancel = () => {
    setShowDelete(false);
  };

  const defaultFormElement:StatePre<IFormElement> = {
    customId: form.basicFormData.customId,
    uuid: uuid(),
    guildId: form.basicFormData.guildId,
    fieldName: "",
    fieldPlaceholder: "",
    minLength: 0,
    maxLength: 0,
    style: 1,
    required: false,
  };


  return (
    <>
      <div className="flex flex-row w-full justify-between">
        <h1>{form.basicFormData.customId}</h1>
        <Button
          onClick={(e) => setShowDelete(true)}
          className="m-2"
          variant="danger"
        >
          Delete
        </Button>{" "}
      </div>
      <ListGroup>
        <ListGroupItem>
          <div>
            {" "}
            <Forms<IFormBasicData>
              isModal={false}
              state={form.basicFormData}
              onStateChange={onFormBasicDataChange}
              fieldMetaData={{
                customId: customIdFieldProps,
                channelId: {
                  ...inboxFieldProps,
                  selectOptions: discordChannels,
                },
                allowedRoles: {...allowRoleFieldProps, multiSelectOptions: allowRoles},
                linkChannelId: {
                  ...linkChannelIdFieldProps,
                  selectOptions: discordChannels,
                },
                messageInteractionId: linkMessageChannelIdFieldProps,
                anonymous: anonymousFieldProps,
              }}
              outSideSubmit={basicDataOutSideSubmit}
            />
          </div>
        </ListGroupItem>

        <FormList<IFormElement, IForm> partName={"entries"} draggable={true} defaultElement={defaultFormElement} states={form.entries} fieldMetaData={{
          fieldName: fieldnameFieldPropsTemplate,
          fieldPlaceholder: placeholderFieldPropsTemplate,
          minLength: minFieldPropsTemplate,
          maxLength: maxFieldPropsTemplate,
          required: requiredFieldPropsTemplate,
          style: styleFieldPropsTemplate,
        }} elementLimit={5} onFormChange={onFormEntriesChange} onValidChange={onDataEntriesValidChang} submit={submit}  />

      </ListGroup>
      <div className="flex justify-around">
        <Button variant="danger" className="m-2">
          Cancel
        </Button>
        {submit < 1 || !validBasicData || !validEntriesData ? (
          <Button
            disabled={noChanges()}
            className="m-2"
            onClick={handleCheckPress}
          >
            check
          </Button>
        ) : (
          <Button
            disabled={noChanges()}
            type="submit"
            className="m-2"
            onClick={handleSubmitPress}
          >
            submit
          </Button>
        )}
      </div>
      <ConfirmModal
        onCancel={deleteCancel}
        onConfirm={deleteConfirm}
        onHide={deleteCancel}
        visibil={showDelete}
        title={
          "Do you really wanna delete the form " + form.basicFormData.customId
        }
      ></ConfirmModal>
    </>
  );
};
