import React, { useEffect, useState } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { IGuild } from "../../models/IDiscord";
import GuildCard from "../../models/GuildCard";
import { Link } from "react-router-dom";
import { useTranslate } from "@tolgee/react";
import { useAuthTokens } from "../../hooks/useAuth";
import { useGuildsContext } from "../../AuthLayer";
import {Container} from "react-bootstrap";

export const Guilds = () => {
  const { guilds } = useGuildsContext();
  const t = useTranslate();

  return (
    <Container>
      <h1>{t("TITLE_GUILDS")}</h1>

      {guilds &&
        guilds.map((item, index) => (
          <div className="float-left m-3" key={index}>
            {item.botAdded ? (
              <Link key={index} to={"/guilds/" + item.id}>
                  <GuildCard {...item}></GuildCard>
              </Link>
            ) : (
                <a
                  key={index}
                  className="myCard"
                  href={
                    "https://discord.com/api/oauth2/authorize?client_id=582815426568781834&permissions=2117463294&guild_id=" +
                    item.id +
                    "&scope=bot%20applications.commands&state=" +
                    item.id +
                    "&response_type=code&redirect_uri=" +
                    process.env.REACT_APP_REDIRECT_URL
                  }
                >
                  <GuildCard key={index} {...item}></GuildCard>
                </a>
            )}
          </div>
        ))}
    </Container>
  );
};
