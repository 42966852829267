import React, { ReactNode, useEffect, useRef } from "react";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { AxiosContext } from "../BaseApp";

type ProviderValue = AxiosInstance;
type DefaultValue = undefined;
export type ContextValue = DefaultValue | ProviderValue;

type AxiosInterceptor = (
  // eslint-disable-next-line no-unused-vars
  value: AxiosRequestConfig<any>
) => AxiosRequestConfig<any> | Promise<AxiosRequestConfig<any>>;

interface ApiInstanceProviderProps {
  requestInterceptors: AxiosInterceptor[];
  responseInterceptors: AxiosInterceptor[];
  children: ReactNode;
}

const ApiInstanceProvider = ({
  requestInterceptors,
  responseInterceptors,
  children,
}: ApiInstanceProviderProps) => {
  const instanceRef = useRef(axios.create());
  useEffect(() => {
    requestInterceptors.forEach((interceptor) => {
      instanceRef.current.interceptors.request.use(interceptor);
    });
    responseInterceptors.forEach((interceptor) => {
      instanceRef.current.interceptors.response.use(interceptor);
    });
  }, []);

  return (
    <AxiosContext.Provider value={instanceRef.current}>
      {children}
    </AxiosContext.Provider>
  );
};

export { ApiInstanceProvider };
