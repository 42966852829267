import ReactDOM from "react-dom";
import "./styles/tailwind.css";
import AppRouter from "./AppRouter";
import * as serviceWorker from "./serviceWorker";
import React from "react";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { getConfig } from "./config";
import { TolgeeProvider } from "@tolgee/react";
import { Tolgee } from "@tolgee/core";
import { UI } from "@tolgee/ui";
import Loading from "./sites/loading/Loading";
import { App } from "./App";
import { BaseApp } from "./BaseApp";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

ReactDOM.render(
  <TolgeeProvider
    staticData={{
        //@ts-ignore
      "en-EN": () => import("./i18n/en.json"),

    }}
    defaultLanguage={'en-EN'}
    ui={process.env.REACT_APP_TOLGEE_API_KEY ? UI : undefined}
    apiUrl={process.env.REACT_APP_TOLGEE_API_URL}
    apiKey={process.env.REACT_APP_TOLGEE_API_KEY}
    loadingFallback={<Loading />}
  >
    <App></App>
  </TolgeeProvider>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
reportWebVitals();
