import React, { useState } from "react";
import { Navbar, Container, Nav, NavDropdown, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslate } from "@tolgee/react";
import { useAuthTokens } from "../../hooks/useAuth";

const MyNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const t = useTranslate();
  const toggle = () => setIsOpen(!isOpen);
  const useTokens = useAuthTokens();

  const logoutWithRedirect = () => {
    useTokens.removeAll();
    logout({
      returnTo: window.location.origin,
    });
  };

  return (
    <div>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand as={Link} to="/">
            LEpEX.xyz
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="w-full">
              <div className="w-full flex lg:flex-row flex-col justify-between">
                <div className="w-full flex lg:flex-row flex-col">
                  <Nav.Link as={Link} to="/features">
                    {t("TITLE_FEATURES")}
                  </Nav.Link>
                  <Nav.Link as={Link} to="/discord">
                    {t("TITLE_DISCORD")}
                  </Nav.Link>
                  <Nav.Link as={Link} to="/help">
                    {t("TITLE_HELP")}
                  </Nav.Link>
                  <Nav.Link as={Link} to="/guilds">
                    {t("TITLE_GUILD_OVERVIEW")}
                  </Nav.Link>
                </div>
                <div>
                  {!isAuthenticated && (
                    <Nav.Link
                      as={Link}
                      to="/guilds"
                      onClick={() => loginWithRedirect()}
                    >
                      {t("TITLE_LOGIN")}
                    </Nav.Link>
                  )}
                  {isAuthenticated && (
                    <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                      <NavDropdown.Item as={Link} to="/guilds">
                        <Container fluid="md">
                          <Row>
                            <Col className="flex flex-row">
                              <img
                                src={
                                  "https://cdn.discordapp.com/avatars/" +
                                  user?.nickname +
                                  "/" +
                                  user?.picture
                                }
                                alt="Profile"
                                className="nav-user-profile rounded-circle"
                                width="50"
                              />
                            </Col>
                            <Col>
                              <p>{user?.name}</p>
                            </Col>
                          </Row>
                        </Container>
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#action/3.2">
                        Guild overview
                      </NavDropdown.Item>

                      <NavDropdown.Divider />
                      <NavDropdown.Item
                        as={Link}
                        to="/"
                        onClick={() => logoutWithRedirect()}
                      >
                        Log out
                      </NavDropdown.Item>
                    </NavDropdown>
                  )}
                </div>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>{" "}
    </div>
  );
};

export default MyNavbar;
