import React, {useEffect, useState} from 'react'
import {useEmbedContext} from "../../contextProvider/EmbedProvider";
import "./embed.css"
import {IEmbedField} from "../../api/types/IEmbed";
import {StatePre} from "../../comp/form/FormTypes";
const { toHTML, htmlTag, parser  } = require('discord-markdown');

export const EmbedViewer = () => {
  const {author, title, basicData, fields, footer} = useEmbedContext();

  const [fieldsInlined, setFieldsInlined] = useState<StatePre<IEmbedField>[][]>([]);
  useEffect(() => {
    inlineFields();
  }, [fields]);

  const inlineFields = () => {
    let outerCounter = 0;
    let innerCounter = 0;
    let countLimit = 0;
    let tempFieldsInlined: StatePre<IEmbedField>[][] = [];
    let tempFieldsInlinedInner: StatePre<IEmbedField>[] = [];
    for(let i = 0; i < fields.length; i++) {
      if(!fields[i].inline) {
        countLimit = 0;
        tempFieldsInlinedInner[0] = fields[i];
        tempFieldsInlined[outerCounter] = tempFieldsInlinedInner;
        tempFieldsInlinedInner = [];
        outerCounter++;
      } else {
        countLimit++;
        if(countLimit === 4) {
          tempFieldsInlined[outerCounter] = tempFieldsInlinedInner;
          tempFieldsInlinedInner = [];
          innerCounter = 0;
          countLimit = 1;
          outerCounter++;
        }
        tempFieldsInlinedInner[innerCounter] = fields[i];
        innerCounter++;
        if((i+1) < fields.length) {
          if(!fields[i+1].inline) {
            tempFieldsInlined[outerCounter] = tempFieldsInlinedInner;
            innerCounter = 0;
            countLimit= 0;
            tempFieldsInlinedInner = [];
            outerCounter++;
          }
        } else {
          tempFieldsInlined[outerCounter] = tempFieldsInlinedInner;
        }
      }
    }
    console.log(tempFieldsInlined);
    setFieldsInlined([...tempFieldsInlined]);
  }

  return (
    <>
      <div className="embed" style={{borderColor: "#" + basicData.color.toString()}}>
        <div className="embed-primary-container">
          <div className="embed-secondary-container">
            <div className="embed-author">
              { author.value && author.value !== "" && (
                  <>
                    {author.iconURL && (
                        <img src={author.iconURL.toString()} className="embed-author-iconUrl" />
                    )
                  }
                  <span className="embed-author-name">{author.value}</span></>)
              }
            </div>
            {title.value && title.value !== "" && (<span className="embed-title">
						{
                          title.titleURL !== "" ? (<a href={title.titleURL.toString()} className="text link">{title.value}</a>) : (<a className="text">{title.value}</a>)
                        }
					</span>)}
            {basicData.description !== "" && (<span className="embed-description">{basicData.description}</span>)}
            {fields.length > 0 && (<div className="embed-fields-container">
              {fieldsInlined.map((innerFieldsInlined,index) => (
                  <div key={index} className="embed-fields-container inline">
                    {
                     innerFieldsInlined.map((e, index) => {
                       return(
                       e.name === "" && e.name === "" ? (<div key={e.uuid.toString()} className="embed-field invisible">
                         <span className="embed-field-title">You Found</span>
                         <span className="embed-field-content">My Secret</span>
                       </div>):(<div key={e.uuid.toString()} className="embed-field">
                         <span className="embed-field-title">{e.name}</span>
                         <span className="embed-field-content">{e.value}</span>
                       </div>))})
                    }
                  </div>
              ))}
              {/*
              <div className="embed-fields-container inline">
                <div className="embed-field">
                  <span className="embed-field-title">Embed Title Inline</span>
                  <span className="embed-field-content">Embed Content Inline</span>
                </div>
                <div className="embed-field">
                  <span className="embed-field-title">Embed Title Inline</span>
                  <span className="embed-field-content">Embed Content Inline</span>
                </div>
              </div>*/}
            </div>)}
          </div>
          {basicData.thumbnailURL !== "" && (  <img src={basicData.thumbnailURL.toString()} className="embed-thumbnail" />)}
        </div>
        {basicData.imageURL !== "" && (<img src={basicData.imageURL.toString()} className="embed-image" />)}
        {footer.value !== "" && (
          <div className="embed-footer">
            {footer.iconURL !== "" && (
                <img className="embed-footer-image" src={footer.iconURL.toString()} />
            )}
            <span className="embed-footer-text">{footer.value}</span>
            {(footer.timeStamp === true) && (<span className="embed-timestamp">Date Sun Apr 25 2021 13:03:13 GMT+0200</span>)

            }

          </div>)}
      </div>

    </>
  )
}

