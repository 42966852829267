import { useAuth0 } from "@auth0/auth0-react";
import axios, {
  AxiosError,
  AxiosRequestHeaders,
  AxiosResponseHeaders,
  Method,
} from "axios";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { AxiosContext } from "../BaseApp";

import { useAuthTokens } from "./useAuth";

export interface ApiRequestError {
  status: number;
  code: string;
  params?: string[];
}

export interface ApiSuccessResponse<T> {
  status: number;
  headers: AxiosResponseHeaders;
  body?: T;
}

export interface UrlParams {
  [key: string]: string | number;
}
export interface apiNames {
  //data?: FormDataValue[];
  data: string;
  auth: string;
  discord: string;
}
const useApiRequest = <T>(
  apiName: keyof apiNames,
  url: string,
  method: Method | string,
  triggerOnLoad: boolean = true,
  payload: any = {},
  headers?: AxiosRequestHeaders | undefined
) => {
  type apiServiceHandler = {
    [key in keyof apiNames]?: ApiService;
  };

  interface ApiService {
    baseURL: string;
    token?: string | null;
  }

  const tokens = useAuthTokens();

  const apiServices: apiServiceHandler = {
    data: {
      baseURL:
        process.env.REACT_APP_API_BASE_URL + "api/v1/" ||
        "http://localhost:8081/api/v1/",
      token: tokens.dcToken,
    },
  };

  const [data, setData] = useState<ApiSuccessResponse<T> | undefined>(
    undefined
  );

  const [error, setError] = useState<ApiRequestError | undefined>(undefined);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const contextInstance = useContext(AxiosContext);
  const instance = useMemo(() => {
    return contextInstance || axios;
  }, [contextInstance]);
  const controllerRef = useRef(new AbortController());
  const { isAuthenticated, loginWithRedirect, getAccessTokenSilently, user } =
    useAuth0();

  const cancel = () => {
    controllerRef.current.abort();
  };

  const executeRequest = async (bodyPayload: any, urlParams?: UrlParams) => {
    // setData(undefined);
    setLoading(true);
    setError(undefined);

    let urlParamsBuilder = url;
    if (urlParams) {
      for (const [key, value] of Object.entries(urlParams)) {
        urlParamsBuilder = urlParamsBuilder.replace(
          `:${key}`,
          value.toString()
        );
      }
    }
    try {
      const response = await instance.request({
        signal: controllerRef.current.signal,
        data: bodyPayload,
        method,
        baseURL: apiServices[apiName]?.baseURL,
        url: urlParamsBuilder,
        headers: {
          Authorization: `Bearer ${apiServices[apiName]?.token}`,
          ...headers,
        },
      });
      const data = {
        body: response.data as T,
        status: response.status,
        headers: response.headers,
      };

      setData(data);
      return data;
    } catch (errorRaw) {
      const error = errorRaw as AxiosError;
      let errorBuilder: ApiRequestError | undefined = undefined;

      if (error?.response) {
        /**
         * Redirect to login after 401 status received
         */
        if (error?.response?.status == 401) {
          //loginWithRedirect();
        }

        const errorPayload: any = error?.response?.data;
        errorBuilder = {
          code: errorPayload?.code ?? error.code ?? "UNKNOWN_API_ERROR",
          status: error.response?.status ?? 500,
          params: errorPayload?.params,
        };
      }
      setError(errorBuilder);
      setData(undefined);
      throw errorBuilder;
    } finally {
      setLoaded(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (triggerOnLoad && isAuthenticated) {
      executeRequest(payload).then();
    }
  }, [tokens.dcToken]);
  return { executeRequest, cancel, data, error, loaded, loading };
};

export { useApiRequest };
