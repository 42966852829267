import { T, useTranslate } from "@tolgee/react";
import React, { useEffect } from "react";
import {
  Col,
  FloatingLabel,
  Form,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { useFormsContext } from "../Forms";
import {
  BasicInputFieldProps,
  ValidationOptions,
  ValidationMethodsMapper,
  MinMaxValidationOption,
} from "../FormTypes";
import {isNumber} from "lodash";

const TextInputField = <T,>({
  inputFieldType,
  returnType = "string",
  name,
  label,
  readOnly = false,
  optional = false,
  inlineSize,
  placeholder = "",
  tooltip,
  validationOptions,
  hide = false,
  uuid,
  validationOnBlur,
}: BasicInputFieldProps<T>) => {
  const { data, errorState, onValueChange, onBlur } = useFormsContext<T>();
  const t = useTranslate();
  //console.log("inputfield render" + name.toString());
  let fieldError = errorState[name];
  const value = data[name];

  const forceFormat = (value: string) => {
    if(inputFieldType === "number") {
      if(!isNaN(parseInt(value))) {
        return parseInt(value);
      } else {
        return 0;
      }
    }
    return value;
  };


  return (
    <Col sm={inlineSize} className="my-1">
      <FloatingLabel
        hidden={hide}
        controlId="floatingInput"
        label={t(label)}
        className="mb-1 ml-1 mr-1 flex flex-col w-full"
      >
        <FormControl
          hidden={hide}
          name={String(name)}
          required={optional}
          readOnly={readOnly}
          type={inputFieldType}
          placeholder={placeholder}
          defaultValue={String(value)}
          onChange={(e) => {
            onValueChange(name, forceFormat(e.currentTarget.value));
          }}
          onBlur={async (e) => {
            onBlur(name, forceFormat(e.currentTarget.value));
          }}
          isInvalid={fieldError ? fieldError.status === "error" : false}
        ></FormControl>
        <Form.Control.Feedback type="invalid">
          {fieldError ? <>{fieldError.message}</> : <>missing error</>}
        </Form.Control.Feedback>
        <Form.Control.Feedback>{t("LOOKS_GOOD_MSG")}</Form.Control.Feedback>
      </FloatingLabel>
    </Col>
  );
};

export default TextInputField;
