import { useTranslate } from "@tolgee/react";
import React, {useEffect, useState} from "react";
import Home from "../home/Home";
import {Col, Container, ListGroup, Row, Tab} from "react-bootstrap";
import {v4 as uuid} from "uuid";
import {Card} from "primereact/card";
import {ModalForm} from "../../comp/form/ModalForm";
import {INotification} from "../../api/types/Notification";
import {
    embedIdFieldProps,
    identifierFieldProps,
    textChannelFieldProps
} from "../socialnotification/SocialNotificationProps";
import {IEmbed, IEmbedField} from "../../api/types/IEmbed";
import {EmbedEditor} from "./EmbedEditor";
import {EmbedProvider} from "../../contextProvider/EmbedProvider";
import {EmbedViewer} from "./EmbedViewer";
import {useDataContext} from "../../contextProvider/ModuleProvider";


const EmbedBuilder = () => {
  const t = useTranslate();
  const {data:dbEmbeds, loaded, refresh} = useDataContext().embeds;

  const defaultEmbed:IEmbed = {
      name: {
          value: "CREATE"
      },
      uuid: "CREATE",
      author: {
          value: "",
          iconURL: "",
          valueURL: "",
      },
      title: {
          value: "",
          titleURL: "",
      },
      basicData: {
          color: "333234",
          content: "",
          thumbnailURL: "",
          imageURL: "",
          description: "",
      },
      fields: [],
      footer: {
          value: "",
          iconURL: "",
          timeStamp: false
      },

  }


  const [embeds, setEmbeds] = useState<IEmbed[]>([{...defaultEmbed, uuid: uuid()}]);

    useEffect(() => {
        if(dbEmbeds) {
            setEmbeds([...dbEmbeds, defaultEmbed]);
        }
    }, [dbEmbeds]);



  return (
      <Container fluid>
      <h1>{t("TITLE_EMBED_BUILDER")}</h1>
        <Tab.Container id="list-group-tabs-example" defaultActiveKey="#link1">
            <Row>
                <Col md={2} sm={12}>
                    <ListGroup>
                        {embeds.map((item, index) => {
                            return (
                                <div key={index}>{item && item.title && (
                                    <ListGroup.Item
                                    action

                                    href={"#" + item.uuid}
                                    className="text-center"
                                    >
                                        {item.name.value}
                                    </ListGroup.Item>
                                    )}</div>
                            )}
                        )}
                    </ListGroup>
                </Col>
                <Col md={10} sm={12}>
                    <Tab.Content>
                        {
                            embeds.map((embed, index) => (
                                <Tab.Pane key={embed.uuid} eventKey={"#" + embed.uuid}>
                                    <EmbedProvider embed={embed} refresh={refresh}>
                                        <Row>
                                            <Col md={6} sm={12}>
                                                <>
                                                    <h1>Edit</h1>
                                                    <EmbedEditor></EmbedEditor>
                                                </>
                                            </Col>
                                            <Col md={6} sm={12}>
                                                <>
                                                    <h1>Preview</h1>
                                                    <EmbedViewer />
                                                </>
                                            </Col>
                                        </Row>
                                    </EmbedProvider>
                                </Tab.Pane>))
                        }
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
      </Container>
  );
};

export default EmbedBuilder;
