import {Link} from "react-router-dom";
import {Card} from "primereact/card";
import React from "react";

export interface FeatureCardProps {
  link: string,
  featureName: string,
  description: string
}
export const FeatureCard = ({link, featureName, description}:FeatureCardProps) => {
  return <Link to={link}><Card  title={featureName} subTitle={description} style={{ width: '25em' }}/></Link>;
}

