import {MultiSelectFieldProps, SelectInputFieldProps, BasicInputFieldProps} from "../../comp/form/FormTypes";
import {IFormElement} from "../../api/types/FormElement";
import {SelectOptions} from "./FormViewer";
import {outOfRangeValidator} from "../../validationHandler/OutOfRangeValidator";
import {fieldNotSameNameHandler} from "../../validationHandler/NotSameNameHandler";
import {IFormBasicData} from "../../api/types/Form";
import {selectNotDefaultHandler} from "../../validationHandler/SelectNotDefaultHandler";
import {CreateNotification} from "../../api/types/Notification";
import {fieldNotEmptyValidator} from "../../validationHandler/NotEmptyValidator";

const fieldTypes: SelectOptions[] = [
    { name: "small field", value: 1 },
    { name: "large field", value: 2 },
];

export const styleFieldPropsTemplate: SelectInputFieldProps<IFormElement> = {
    label: "Input type",
    name: "style",
    inline: false,
    inputFieldType: "select",
    selectOptions: fieldTypes,
};

export const requiredFieldPropsTemplate: SelectInputFieldProps<IFormElement> = {
    label: "required",
    name: "required",
    inline: true,
    inlineSize: 2,
    inputFieldType: "switch",
};

export const minFieldPropsTemplate: BasicInputFieldProps<IFormElement> = {
    label: "MINIMAL_AMOUNT",
    name: "minLength",
    inline: false,
    inlineSize: 5,
    inputFieldType: "number",
    returnType: "number",
    validationOnBlur: {notEmptyValidation: fieldNotEmptyValidator}
};

export const maxFieldPropsTemplate: BasicInputFieldProps<IFormElement> = {
    label: "MAXIMAL_AMOUNT",
    name: "maxLength",
    inline: true,
    inlineSize: 5,
    inputFieldType: "number",
    returnType: "number",
};

//const sameStringValues: string[] = elements.map((value) => {const preValue = value.fieldName; if(preValue && typeof preValue === "string") {return preValue}});
const sameStringValues: string[] = [];

export const fieldnameFieldPropsTemplate: BasicInputFieldProps<IFormElement> = {
    label: "fieldName",
    name: "fieldName",
    inputFieldType: "string",
    validationOptions: {
        minMaxValidation: { min: 0, max: 100 - 13 },
        notSameStringValidation: {
            stringValues: sameStringValues,
            maxRepeatAmount: 1,
        },
    },

    validationOnBlur: {
        minMaxValidation: outOfRangeValidator,
        notSameStringValidation: fieldNotSameNameHandler,
    },

    validationOnSubmit: {
        minMaxValidation: outOfRangeValidator,
        notSameStringValidation: fieldNotSameNameHandler,
    },
    inline: true,
};

export const placeholderFieldPropsTemplate: BasicInputFieldProps<IFormElement> =
    {
        label: "placeholder",
        name: "fieldPlaceholder",
        inputFieldType: "string",
        validationOptions: {
            minMaxValidation: { min: 0, max: 100 - 13 },

        },

        validationOnBlur: {
            minMaxValidation: outOfRangeValidator,

        },

        validationOnSubmit: {
            minMaxValidation: outOfRangeValidator,
        },
        inline: false,
    };

//Form viewer props
export const customIdFieldProps: BasicInputFieldProps<IFormBasicData> = {
    inputFieldType: "string",
    name: "customId",
    label: "Formname",
    readOnly: true,
    inline: false,
};

export const linkMessageChannelIdFieldProps: BasicInputFieldProps<IFormBasicData> =
    {
        inputFieldType: "number",
        name: "messageInteractionId",
        label: "Message ID",
        inline: false,
    };

export const anonymousFieldProps: SelectInputFieldProps<IFormBasicData> = {
    inputFieldType: "switch",
    name: "anonymous",
    label: "Anonymous",
    inline: false,
    isSwitch: true,
};

export const inboxFieldProps: SelectInputFieldProps<IFormBasicData> = {
    inputFieldType: "select",
    name: "channelId",
    label: "Mailbox Channel / Category",
    selectDescribe: "select the inbox channel or category",
    inline: false,
    //not working because it almost covered by onblur
    validationOnSubmit: { selectNotDefault: selectNotDefaultHandler },
    validationOnChange: { selectNotDefault: selectNotDefaultHandler },
};



export const linkChannelIdFieldProps: SelectInputFieldProps<IFormBasicData> = {
    inputFieldType: "select",
    name: "linkChannelId",
    label: "Link channel",
    selectDescribe: "select the channel with the message",
    inline: false,
};

export const allowRoleFieldProps: MultiSelectFieldProps<IFormBasicData> = {
    inputFieldType: "multiselect",
    name: "allowedRoles",
    label: "allowed roles",
    inline: false,
    inlineSize: 12,
}