import React, {useEffect, useState} from 'react'
import {T, useTranslate} from "@tolgee/react";
import {Col, Container, ListGroup, Row, Tab} from 'react-bootstrap';
import {Card} from "primereact/card";
import ConfirmModal from "../../comp/confirm/ConfirmModal";
import {ModalForm} from "../../comp/form/ModalForm";
import {CreateNotification, INotification} from "../../api/types/Notification";
import {StatePre} from "../../comp/form/FormTypes";
import {useDataContext} from "../../contextProvider/ModuleProvider";
import {SelectOptions} from "../formbuilder/FormViewer";
import {v4 as uuid } from "uuid"
import {embedIdFieldProps, identifierFieldProps, textChannelFieldProps} from "./SocialNotificationProps";
import {useParams} from "react-router-dom";
import {NotFound} from "../notfound/NotFound";
import {useApiRequest} from "../../hooks/useApiRequest";
export interface SocialNotificationItem {
    name: string,
    href: string,
    page: number,
    endpointUrl: string,
    items?: INotification[]
}


export const SocialNotification = () => {
    const t = useTranslate();
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [editState, setEditState] = useState<StatePre<INotification>>();
    const [createState, setCreateState] = useState<StatePre<INotification>>();
    const { data: discordData } = useDataContext().discordData;
    const { data: socialData, refresh: refreshSocial } = useDataContext().socialNotification;
    const [selectOptionsUrl, setSelectOptionsUrl] = useState("");
    const { id } = useParams();
    const {executeRequest} = useApiRequest("data","social/:page/create", "POST",false);
    useEffect(() => {
        return () => {
            console.log("createState Changes:", createState);
        };
    }, [createState]);
    if(!id || !socialData) {
        return <NotFound></NotFound>
    }
    let discordChannels: SelectOptions[] = [];
    if (discordData) {
        discordChannels = discordData?.discordChannels
            .filter((e) => e.channelType === 0)
            .map((value, index) => {
                const prefix = value.channelType === 0 ? "- " : "# ";
                return { name: prefix + value.channelName, value: value.channelId };
            });
    }
    let embeds: SelectOptions[] = [
        {name: "youtube", value: "12123"},
        {name: "twitch", value: "124345"},
        {name: "twitter", value: "1234"},
    ];

    /*
    * {name:"Instagram", href: "instagram", endpointUrl: "social/instagram/channels?name=:name", page:3},
        {name:"Twitter", href: "twitter", endpointUrl: "social/twitter/channels?name=:name",page:4},
        {name:"TikTok", href: "tiktok", endpointUrl: "social/tiktok/channels?name=:name",page:5},
    * */
    const socialNotificationItems:SocialNotificationItem[] = [
        {name:"YouTube", href: "youtube", endpointUrl: "social/youtube/channels?name=:name", page:1, items: socialData?.youtube},
        {name:"Twitch", href: "twitch", endpointUrl: "social/twitch/channels?name=:name", page:2, items: socialData?.twitch},
    ]

    interface ICreateNotificationPanel {
        page: number,
        guild: string,
        name: string,
    }

    const createNotificationPanel = (item: SocialNotificationItem, notification?: INotification) => {
        setSelectOptionsUrl(item.endpointUrl);
        if(!notification) {
            setDefaultData(item.href);
        } else {
            setEditState({...notification});
        }
        setShowCreateModal(true);
    };

    const setDefaultData = (page: string) => {
        const defaultState = {guildId: id, displayName: "", textChannelId: "0", page: page, embedId: "0", identifier: "0", uuid: uuid()};
        setEditState(defaultState);
        setCreateState(defaultState);
    }

    const onHandleClose = () => {
        setShowCreateModal(false);
    }

    const onStateChange = (data: StatePre<INotification>) => {
        setEditState(data);
    }

    const onFormValidSubmit = async () => {
        if(editState && editState.page) {
            setShowCreateModal(false);
            await executeRequest({...editState}, {page: editState?.page.toString()});
            refreshSocial();
        }
    };

    return (
        <Container>
            <h1>{t("TITTLE_SOCIAL_MEDIA_NOTIFICATION")}</h1>
            <Tab.Container id="list-group-tabs-example" defaultActiveKey="#link1">
                <Row>
                    <Col sm={4}>
                        <ListGroup>{socialNotificationItems.map((item, index) => (
                            <ListGroup.Item
                                action
                                key={index}
                                href={"#" + item.href}
                                className="text-center"
                            >
                                {item.name}
                            </ListGroup.Item>)
                        )}

                        </ListGroup>
                    </Col>
                    <Col sm={8}>
                        {" "}
                        <Tab.Content>
                            <>
                                {socialNotificationItems.map((item, index) => (

                                    <Tab.Pane key={index} eventKey={"#" + item.href}>
                                        <Container>
                                            <h2>{item.name}</h2>
                                            <Row className="mb-4">
                                                <Card onClick={(e) => {createNotificationPanel(item)}} title={"Create Notification"} subTitle={"Create a new notification to " + item.name + " Channel"} style={{ width: '25em', cursor : 'pointer'  }}/>
                                            </Row>
                                            <Row className="mb-4">
                                                {item.items && item.items.length >= 1 && (item.items.map((notification, index) => (
                                                    <Col className="p-0">
                                                        <Card key={notification.uuid} title={notification.displayName} subTitle={"You subscribe to the channel of " + notification.displayName + " on " + notification.page} style={{ width: '25em', cursor : 'pointer'  }}/>
                                                    </Col>
                                                )))}
                                            </Row>
                                        </Container>
                                    </Tab.Pane>
                                ))}
                                { editState && (<ModalForm<INotification>
                                    title={"Create Notification"}
                                    showModal={showCreateModal}
                                    handleClose={onHandleClose}
                                    formProps={{isModal: false,
                                        fieldMetaData:{
                                            textChannelId: {...textChannelFieldProps, selectOptions: discordChannels},
                                            embedId: {...embedIdFieldProps, selectOptions: embeds},
                                            identifier: {...identifierFieldProps, selectOptionsUrl: selectOptionsUrl, searchKey: "displayName"},
                                        },
                                        state: editState,
                                        onSubmitValid: onFormValidSubmit,
                                        onStateChange: onStateChange}}/>)
                                }
                            </>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </Container>
    )
}

