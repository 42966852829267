import { Visibility } from "@mui/icons-material";
import React from "react";
import { Button, Modal } from "react-bootstrap";

export interface confirmModalProps {
  title: string;
  onConfirm: () => void;
  onCancel: () => void;
  onHide?: () => void;
  visibil: boolean;
}

const ConfirmModal = ({
  title,
  onConfirm,
  onCancel,
  onHide,
  visibil,
}: confirmModalProps) => {
  return (
    <Modal show={visibil} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          cancel
        </Button>
        <Button type="submit" variant="primary" onClick={onConfirm}>
          confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
