import axios, { AxiosInstance, Method } from "axios";
import { once } from "lodash";
import React, { useContext, useEffect, useMemo } from "react";

import { IForm } from "../api/types/Form";
import { AxiosContext } from "../BaseApp";
import { useAuthTokens } from "../hooks/useAuth";
import useState from "react-usestateref";
import { IGuild } from "../models/IDiscord";
import { useGuildsContext } from "../AuthLayer";
import { useParams } from "react-router-dom";
import { useAppStartLoading } from "../hooks/useAppStartLoading";
import { IGuildDTO } from "../api/types/Discord";
import {INotificationSubscriptions} from "../api/types/Notification";
import {IEmbed} from "../api/types/IEmbed";

export interface ContextProviderProps {
  children: React.ReactNode;
}

export interface ContextProviderAction<T> {
  refresh: () => void;
  data: T | undefined;
  loaded: boolean;
}

export interface ContextProviderConfiguration<T> {
  name: keyof T;
  baseURL: string;
  url: string;
  token: string | null;
  method: Method | string;
}

export interface ContextDataProvider {
  forms: ContextProviderAction<IForm[]>;
  discordData: ContextProviderAction<IGuildDTO>;
  socialNotification: ContextProviderAction<INotificationSubscriptions>;
  //channels: ContextProviderAction<IGuildChannel[]>;
  embeds: ContextProviderAction<IEmbed[]>;
}

interface Configuration<T> extends ContextProviderConfiguration<T> {}

const createDataContext = once(() =>
  React.createContext({} as ContextDataProvider)
);
export const useDataContext = () => useContext(createDataContext());

export const replaceUrlParams = (
  guild: IGuild | undefined,
  rawUrl: string,
  page?: number
): string => {
  if (guild && guild?.id) {
    return rawUrl.replace(":guildId", guild?.id.toString());
  }
  return rawUrl;
};

export const executeApiRequest = async <T,>(
  instance: AxiosInstance,
  guild: IGuild | undefined,
  config: Configuration<T>
) => {
  const { url, method } = config;
  const { data, status } = await instance.request({
    headers: {
      Authorization: `Bearer ${config.token}`,
    },
    method,
    baseURL: config.baseURL,
    url: `/api/v1/${replaceUrlParams(guild, url)}`,
  });
  if(status === 404) {
    return undefined;
  }
  return data;
};

const DataProvider = ({ children }: ContextProviderProps) => {
  const context = createDataContext();
  const tokens = useAuthTokens();
  const { id } = useParams();
  const axiosContextInstance = useContext(AxiosContext);
  const { appLoading } = useAppStartLoading();
  const axiosInstance = useMemo(() => {
    return axiosContextInstance || axios;
  }, [axiosContextInstance]);

  const [contextDataProvider, setContextDataProvider, ref] =
    useState<ContextDataProvider>();

  const configurations: Configuration<ContextDataProvider>[] = [
    {
      name: "forms",
      method: "GET",
      baseURL: process.env.REACT_APP_API_BASE_URL || "localhost:8081",
      url: "form/:guildId/all-form-data",
      token: tokens.dcToken,
    },
    {
      name: "discordData",
      method: "GET",
      baseURL: process.env.REACT_APP_API_BASE_URL || "localhost:8081",
      url: "discord/:guildId/guildData",
      token: tokens.dcToken,
    },
    {
      name: "socialNotification",
      method: "GET",
      baseURL: process.env.REACT_APP_API_BASE_URL || "localhost:8081",
      url: "social/:guildId/subscriptions",
      token: tokens.dcToken,
    },{
      name: "embeds",
      method: "GET",
      baseURL: process.env.REACT_APP_API_BASE_URL || "localhost:8081",
      url: "embed/:guildId/all-embeds",
      token: tokens.dcToken,
    }
  ];
  const { getActiveGuild } = useGuildsContext();

  const deleteModuleObject = async () => {};

  const insertModuleObject = async () => {};

  const startContextHandler = async () => {
    let tmpState: ContextDataProvider = {} as ContextDataProvider;

    for await (const config of configurations) {
      /**
       * Refresh methode to update
       * @param page
       */
      const refresh = async () => {
        setContextDataProvider({
          ...(ref.current as ContextDataProvider),
          [config.name]: {
            refresh,
            data: await executeApiRequest(
              axiosInstance,
              getActiveGuild(id),
              config
            ),
            loaded: true,
          },
        });
      };

      tmpState = {
        ...tmpState,
        [config.name]: {
          refresh,
          data: await executeApiRequest(
            axiosInstance,
            getActiveGuild(id),
            config
          ),
          loaded: true,
        },
      };
    }

    setContextDataProvider(tmpState);
  };
  useEffect(() => {
    if (!appLoading && id) {
      startContextHandler().then();
    }
  }, [appLoading, id]);

  /**
   * Handle empty state for component rendering
   */
  useEffect(() => {
    const tmpState: ContextDataProvider = {} as ContextDataProvider;
    for (const config of configurations) {
      tmpState[config.name] = {
        data: undefined,
        refresh: (page?: number) => {},
        loaded: false,
      };
    }
    setContextDataProvider(tmpState);
  }, []);

  if (!contextDataProvider) return <></>;
  return (
    <context.Provider value={contextDataProvider}>{children}</context.Provider>
  );
};

export default DataProvider;
