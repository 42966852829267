import { useTranslate } from "@tolgee/react";
import React from "react";
import Home from "../home/Home";
import {Container} from "react-bootstrap";

const Help = () => {
  const t = useTranslate();
  return (
    <Container>
      <h1>{t("TITLE_HELP")}</h1>
      <Home></Home>
    </Container>
  );
};

export default Help;
