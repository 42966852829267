import { useTranslate } from "@tolgee/react";
import React from "react";
import {Container, Spinner} from "react-bootstrap";


const Loading = () => {
  const t = useTranslate();
  return (
    <Container>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </Container>
  );
};

export default Loading;
