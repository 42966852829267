import { useAuth0 } from "@auth0/auth0-react";
import { useTranslate } from "@tolgee/react";
import React, {useEffect, useState} from "react";
import {
  Navbar,
  Container,
  Nav,
  NavDropdown,
  Row,
  Col,

  Button,
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useGuildsContext } from "../../AuthLayer";
import { useDataContext } from "../../contextProvider/ModuleProvider";
import { NotFound } from "../notfound/NotFound";
import {Card} from "primereact/card";
import {FeatureCard, FeatureCardProps} from "./FeatureCard";


export const FeatureOverview = () => {
  const { id } = useParams();
  const t = useTranslate();
  const { guilds } = useGuildsContext();
  const { discordData } = useDataContext();

  useEffect(() => {
    console.log("data: ", discordData);
  }, [discordData]);

  if (!guilds?.some((e) => String(e.id) === id)) {
    return <NotFound></NotFound>;
  }

  if (!discordData.data) {
    return (
      <Button
        href={
          "https://discord.com/api/oauth2/authorize?client_id=582815426568781834&permissions=2117463294&guild_id=" +
          id +
          "&scope=bot%20applications.commands&state=" +
          id +
          "&response_type=code&redirect_uri=" +
          process.env.REACT_APP_REDIRECT_URL
        }
      >
        Add Bot to Server
      </Button>
    );
  }



  const features:FeatureCardProps[] = [
    {link:"settings", featureName: "Bot settings", description: "you can create a form with that"},
    {link:"forms", featureName: "Form creator", description: "you can create a form with that"},
    {link:"embeds", featureName: "Embed builder", description: "create your Embed"},
    {link:"social", featureName: "Social media notification", description: "you can create a form with that"},
    {link:"protection", featureName: "Protection settings", description: "you can create a form with that"},
    {link:"moderation", featureName: "Moderation", description: "you can create a form with that"},
  ];


  return (
      <Container>
        <h1>{t("TITLE_FEATURE_OVERVIEW")}</h1>
        {features.map((feature, index) => (<div className="float-left m-3" key={index}><FeatureCard {...feature} /></div>))}
      </Container>
  );
};


